var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showCityworksErrorDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _c("p", [
              _vm._v(
                " Error 404: Unable to create form submission. Contact customer support "
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.setCityworksError(undefined)
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }