<template>
  <v-dialog :value="showCityworksErrorDialog" max-width="500px" persistent>
    <v-card>
      <v-card-text class="py-3">
        <p>
          Error 404: Unable to create form submission. Contact customer support
        </p>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text color="#3F51B5" @click="setCityworksError(undefined)">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "CityworksErrorDialog",
  props: {
    showCityworksErrorDialog: Boolean,
  },
  methods: {
    ...mapMutations(["setCityworksError"]),
  },
};
</script>
