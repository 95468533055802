var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showAlreadyClearedDialog, "max-width": "500px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _vm._v(
                          " Ticket " +
                            _vm._s(
                              _vm.ticketNumbersAlreadySubmittedWithoutDuplicates.join(
                                ", "
                              )
                            ) +
                            " has been cleared. Do you want to permanently delete the forms for that ticket that have been saved on this device? If you keep your form, it will be submitted and overwrite the form that was previously submitted. "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      _vm.$emit("delete-forms")
                      _vm.deleteForms()
                    }
                  }
                },
                [_vm._v(" Delete Forms ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("keep-forms")
                    }
                  }
                },
                [_vm._v(" Keep Forms ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }