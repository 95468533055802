import LogRocket from "logrocket";

export default {
  methods: {
    initLogRocket() {
      try {
        const auth = JSON.parse(localStorage.getItem("auth"));
        if (auth?.log_activity) {
          LogRocket.init("vur822/apputilisynccom");
          LogRocket.identify(auth.user_id, {
            name: `${auth.f_name} ${auth.l_name}`,
            email: auth.email,
            organization: auth.organization.name,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
