var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      !_vm.$route.path.includes("login") && !_vm.$route.path.includes("signup")
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                permanent: _vm.isMenuOpen && _vm.$vuetify.breakpoint.smAndUp,
                bottom: _vm.$vuetify.breakpoint.xsOnly,
                stateless: _vm.$vuetify.breakpoint.smAndUp,
                app: "",
                color: "#FAFAFA",
                value: _vm.isMenuOpen
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "prepend",
                    fn: function() {
                      return [
                        _c("v-list-item", { staticClass: "mx-0 px-0" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/UtiliSyncDocsLogo.svg"),
                              id: "logo"
                            }
                          })
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1934580761
              )
            },
            [
              _vm.showSharedSiteNavDrawerList
                ? _c("SharedSiteNavDrawerList")
                : _c("NavDrawerList", {
                    attrs: { showUploadTickets: _vm.showUploadTickets },
                    on: {
                      "open-capture-upload-dialog": function($event) {
                        _vm.showCaptureOrUploadDialog = true
                      }
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000 },
          scopedSlots: _vm._u(
            [
              _vm.numUnsubmittedTickets > 0
                ? {
                    key: "action",
                    fn: function(ref) {
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._b(
                            {
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/upload")
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [_vm._v(" Upload Tickets ")]
                        )
                      ]
                    }
                  }
                : null
            ],
            null,
            true
          ),
          model: {
            value: _vm.showOnline,
            callback: function($$v) {
              _vm.showOnline = $$v
            },
            expression: "showOnline"
          }
        },
        [_vm._v(" You're back online. ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000 },
          model: {
            value: _vm.showOffline,
            callback: function($$v) {
              _vm.showOffline = $$v
            },
            expression: "showOffline"
          }
        },
        [
          _vm._v(
            " You're offline. Not to worry! You can still complete forms and submit them later. "
          )
        ]
      ),
      _c("v-snackbar", {
        attrs: { timeout: -1 },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("div", [_vm._v("UtiliSync was just updated.")]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              href: _vm.$router.resolve({
                                path: "/versionLog"
                              }).href,
                              text: ""
                            }
                          },
                          [_vm._v(" View What's New ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            _vm.showUpdateSnackbar = false
                          }
                        }
                      },
                      [
                        _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.showUpdateSnackbar,
          callback: function($$v) {
            _vm.showUpdateSnackbar = $$v
          },
          expression: "showUpdateSnackbar"
        }
      }),
      _c("AlreadyClearedDialog", {
        attrs: {
          showAlreadyClearedDialog: _vm.showAlreadyClearedDialog,
          ticketNumbersAlreadySubmitted: _vm.ticketNumbersAlreadySubmitted,
          formResultIdsAlreadySubmitted: _vm.formResultIdsAlreadySubmitted
        },
        on: {
          "delete-forms": function($event) {
            _vm.showAlreadyClearedDialog = false
          },
          "keep-forms": function($event) {
            _vm.showAlreadyClearedDialog = false
          }
        }
      }),
      _c("ErrorDialog", { attrs: { showErrorDialog: _vm.errorOccurred } }),
      _c("BadRequestErrorDialog", {
        attrs: { showBadRequestErrorDialog: Boolean(_vm.badRequestError) }
      }),
      _c("ForbiddenErrorDialog", {
        attrs: { showForbiddenErrorDialog: Boolean(_vm.forbiddenError) },
        on: {
          "cityworks-token-error": function($event) {
            _vm.showCityworksSignInDialog = true
          }
        }
      }),
      _c("CityworksErrorDialog", {
        attrs: { showCityworksErrorDialog: Boolean(_vm.cityworksError) }
      }),
      _vm.showCityworksSignInDialog
        ? _c("CityworksSignInDialog", {
            attrs: { showCityworksSignInDialog: _vm.showCityworksSignInDialog },
            on: {
              "cityworks-sign-in-dialog-close": function($event) {
                _vm.showCityworksSignInDialog = false
              },
              "cityworks-sign-in-dialog-succeed": function($event) {
                _vm.showCityworksSignInDialog = false
                _vm.updateCityworksToken()
              }
            }
          })
        : _vm._e(),
      _vm.showCaptureOrUploadDialog
        ? _c("CaptureOrUploadDialog", {
            attrs: { showCaptureOrUploadDialog: _vm.showCaptureOrUploadDialog },
            on: {
              "capture-upload-dialog-close": function($event) {
                _vm.showCaptureOrUploadDialog = false
              },
              "open-capture-toolbar": function($event) {
                _vm.showCaptureOrUploadDialog = false
                _vm.showCaptureToolbar = true
              }
            }
          })
        : _vm._e(),
      _vm.showCaptureShowWidget
        ? _c("CaptureShowWidget", {
            attrs: {
              showCaptureShowWidget: _vm.showCaptureShowWidget,
              videoURL: _vm.videoURL
            },
            on: {
              "capture-show-dialog-close": function($event) {
                _vm.videoURL = undefined
                _vm.showCaptureShowWidget = false
              },
              "close-capture-toolbar": function($event) {
                _vm.showCaptureToolbar = false
              }
            }
          })
        : _vm._e(),
      _vm.showCaptureToolbar
        ? _c("CaptureToolBar", {
            on: {
              "close-capture-toolbar": function($event) {
                _vm.showCaptureToolbar = false
              },
              "finished-recording": function($event) {
                _vm.videoURL = $event
                _vm.showCaptureShowWidget = true
              }
            }
          })
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }