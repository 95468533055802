export default {
  data() {
    return {
      isOnline: navigator.onLine,
    };
  },
  methods: {
    onNetworkStatusChange() {
      this.isOnline = navigator.onLine;
    },
  },
  beforeMount() {
    window.addEventListener("online", this.onNetworkStatusChange);
    window.addEventListener("offline", this.onNetworkStatusChange);
  },
  beforeDestroy() {
    window.addEventListener("online", this.onNetworkStatusChange);
    window.addEventListener("offline", this.onNetworkStatusChange);
  },
};
