<template>
  <Layout>
    <router-view :key="$route.fullPath"></router-view>
  </Layout>
</template>

<script>
import Layout from "@/components/app/Layout.vue";

export default {
  name: "App",
  components: {
    Layout,
  },
};
</script>
