import Vue from "vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  email,
  max,
  min,
  numeric,
  required,
  regex,
  double,
  min_value,
} from "vee-validate/dist/rules";

extend("email", email);
extend("max", max);
extend("min", min);
extend("numeric", numeric);
extend("double", double);
extend("required", required);
extend("regex", regex);
extend("min_value", min_value);
extend("datesameorafter", {
  params: ["target"],
  validate(value, { target }) {
    return +new Date(target) <= +new Date(value);
  },
  message: "Start Date must be earlier than End Date.",
});

extend("passwordsMatch", {
  params: ["target"],
  validate(value, { target }) {
    return target === value;
  },
  message: "Password do not match.",
});
extend("hasAtLeastOneUpperChar", {
  validate(value) {
    return /[A-Z]+/.test(value);
  },
  message: "One upper case letter.",
});
extend("hasAtLeastOneNumber", {
  validate(value) {
    return /\d+/.test(value);
  },
  message: "One number",
});
extend("hasAtLeastOneLowerChar", {
  validate(value) {
    return /[a-z]+/.test(value);
  },
  message: "One lower case letter",
});
extend("passwordMin", {
  ...min,
  message: (_, { length }) => {
    length;
    return `Eight characters minimum`;
  },
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
