import moment from "moment";
import Vue from "vue";
import ITEM_TYPES from "@/constants/markupItemTypes";
import ACTION_ITEM_STATUSES_MAP from "@/constants/actionItemStatusesMap";

Vue.filter("formatDayDate", (value) => {
  if (value) {
    return moment.utc(new Date(value)).format("ddd MMM DD, YYYY");
  }
});

Vue.filter("formatTime", (value) => {
  if (value) {
    return moment(new Date(value)).format("hh:mm A");
  }
});

Vue.filter("formatDate", (value) => {
  if (value) {
    return moment(new Date(value)).format("MM/DD/YYYY");
  }
});

Vue.filter("formatUtcDate", (value) => {
  if (value) {
    return moment.utc(new Date(value)).format("MM/DD/YYYY");
  }
});

Vue.filter("formatFullDate", (value) => {
  if (value) {
    return moment.utc(new Date(value)).format("MMM DD, YYYY, hh:mm A");
  }
});

Vue.filter("formatLocalFullDate", (value) => {
  if (value) {
    return moment(new Date(value)).format("MMM DD, YYYY, hh:mm A");
  }
});

Vue.filter("formatDateTime", (value) => {
  if (value) {
    return moment(new Date(value)).format("MM/DD/YY HH:MM a");
  }
});

Vue.filter("formatVariableDate", (value, format) => {
  if (value) {
    return moment.utc(new Date(value)).format(format);
  }
});

Vue.filter("formatVariableLocalDate", (value, format) => {
  if (value) {
    return moment(new Date(value)).format(format);
  }
});

Vue.filter("formatVariableDueDate", (value, format) => {
  if (value) {
    return moment.utc(new Date(value)).format(format);
  }
});

Vue.filter("formatRevisionDate", (value, format) => {
  if (value) {
    return moment(new Date(value.replace(/, /g, "T"))).format(format);
  }
});

Vue.filter("timeAgo", (value) => {
  if (value) {
    return moment(new Date(value)).fromNow();
  }
});

Vue.filter("capitalize", (value) => {
  return `${value[0].toUpperCase()}${value.slice(1)}`;
});

Vue.filter("date", (value) => {
  if (value) {
    return moment.utc(new Date(value)).format("MM/DD/YY");
  }
});

Vue.filter("time", (value) => {
  if (value) {
    return moment(new Date(value)).format("h:mm A");
  }
});

Vue.filter("actionItemStatus", (value) => {
  if (value) {
    return ACTION_ITEM_STATUSES_MAP[value];
  }
});

const ITEM_TYPE_MAP = {
  [ITEM_TYPES.TEXT]: "<b>text</b>",
  [ITEM_TYPES.LINE]: "a <b>line</b>",
  [ITEM_TYPES.SHAPE]: "a <b>shape</b>",
  [ITEM_TYPES.SKETCH]: "a <b>sketch</b>",
  [ITEM_TYPES.ARROW]: "an <b>arrow</b>",
  [ITEM_TYPES.LEGEND]: "a <b>legend</b>",
};

Vue.filter("itemTypeFilter", (value) => {
  if (value) {
    return ITEM_TYPE_MAP[value];
  }
});

const TASK_FIELDS_MAP = {
  due: "Due",
  assignedTo: "Assigned To",
  formDefinitionId: "Form",
  description: "Description",
  featureId: "Site",
  status: "Status",
  assignedBy: "Assigned By",
  assignedOn: "Assigned On",
  taskTypeId: "Task Type",
};

Vue.filter("taskFields", (value) => {
  return TASK_FIELDS_MAP[value];
});

const ACTION_ITEM_FIELDS_MAP = {
  due: "Due",
  assignedTo: "Assigned To",
  site: "Site",
  description: "Description",
  status: "Status",
};

Vue.filter("actionItemFields", (value) => {
  return ACTION_ITEM_FIELDS_MAP[value];
});

const FORM_SUBMISSION_FIELDS_MAP = {
  site: "Reference",
  formTitle: "Form",
  submittedBy: "Submitted By",
  updatedOn: "Last Updated",
  overallTaskStatus: "Utilibot Status",
};

Vue.filter("formSubmissionFields", (value) => {
  return FORM_SUBMISSION_FIELDS_MAP[value];
});

const FILES_FIELDS_MAP = {
  date: "Date",
};

Vue.filter("filesFieldMap", (value) => {
  return FILES_FIELDS_MAP[value];
});
