const ITEM_TYPES = {
  IMAGE: "image",
  TEXT: "text",
  LINE: "line",
  SKETCH: "sketch",
  SHAPE: "shape",
  POINT: "point",
  ARROW: "arrow",
  LEGEND: "legend",
};

export default ITEM_TYPES;
