var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", persistent: "" },
      model: {
        value: _vm.showCaptureOrUploadDialog,
        callback: function($$v) {
          _vm.showCaptureOrUploadDialog = $$v
        },
        expression: "showCaptureOrUploadDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("v-toolbar-title", [_c("div", [_vm._v("Capture or Upload")])]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.tryClose()
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _vm.uploadPercentage < 100
            ? _c("v-card-text", { staticClass: "pa-3" }, [
                _c("div", [
                  _vm._v(
                    " Use this tool to create a screen recording or upload an existing file (image or video) to send to UtiliSync Support. "
                  )
                ]),
                !_vm.isMobile
                  ? _c("div", [
                      _c("div", { staticClass: "text-subtitle-1 mt-4" }, [
                        _vm._v("Take a screen recording.")
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center mt-2" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: "#3F51B5",
                                disabled: _vm.isUploading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("open-capture-toolbar")
                                }
                              }
                            },
                            [_vm._v("Open Capture Tool")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "my-4 text-h6",
                          staticStyle: { "text-align": "center" }
                        },
                        [_vm._v("OR")]
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v(" Upload captured screenshot or video. ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-n1 ml-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "#00A9F4" },
                          on: {
                            click: function($event) {
                              _vm.showCaptureHelp = true
                            }
                          }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c("file-pond", {
                      ref: "files",
                      staticClass: "mt-2 cursor-pointer",
                      staticStyle: { width: "400px" },
                      attrs: {
                        name: "files",
                        "label-idle":
                          "<span class='filepond--label-action'> Browse</span> or drop photo or video here...",
                        "accepted-file-types":
                          "image/heic, image/jpeg, image/png, video/mp4, video/mov, video/webm",
                        fileValidateTypeLabelExpectedTypes:
                          "Allows .png, .jpg, .heic, .mp4, .webm, .mov",
                        files: _vm.uploadedFile
                      },
                      on: {
                        addfile: function($event) {
                          return _vm.uploadFile()
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "mt-2" }, [
                  _vm.isUploading
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              color: "#00A9F4",
                              height: "25",
                              rounded: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var value = ref.value
                                    return [
                                      _c("strong", [
                                        _vm._v(_vm._s(Math.ceil(value)) + "%")
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3002676263
                            ),
                            model: {
                              value: _vm.uploadPercentage,
                              callback: function($$v) {
                                _vm.uploadPercentage = $$v
                              },
                              expression: "uploadPercentage"
                            }
                          })
                        ],
                        1
                      )
                    : _c("div", { staticStyle: { height: "25px" } })
                ])
              ])
            : _c("v-card-text", { staticClass: "pa-4" }, [
                _c("div", [
                  _vm._v(
                    " The capture link has been created. Copy the capture link below and send to UtiliSync Support. "
                  )
                ])
              ]),
          _vm.uploadPercentage < 100
            ? _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end px-5 pt-0 pb-3" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "#00A9F4",
                        text: "",
                        to: "/captures",
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v("View Captures "),
                      _c("v-icon", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.mdiOpenInNew))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end px-5 pt-0 pb-3" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "#00A9F4",
                        text: "",
                        to: "" + _vm.uploadedLink,
                        target: "_blank"
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v(_vm._s(_vm.mdiOpenInNew))
                      ]),
                      _vm._v(" View")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "#3F51B5", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.copyUploadLink()
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v(_vm._s(_vm.mdiContentCopy))
                      ]),
                      _vm._v("Copy")
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000 },
          model: {
            value: _vm.showCopiedLinkSnack,
            callback: function($$v) {
              _vm.showCopiedLinkSnack = $$v
            },
            expression: "showCopiedLinkSnack"
          }
        },
        [_vm._v(" Copied upload link! ")]
      ),
      _vm.isUploading
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "450px" },
              model: {
                value: _vm.showWarningDialog,
                callback: function($$v) {
                  _vm.showWarningDialog = $$v
                },
                expression: "showWarningDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "elevation-0",
                      attrs: { dark: "", width: "100%", color: "#3F51B5" }
                    },
                    [
                      _c("v-toolbar-title", [
                        _c("div", [_vm._v("Currently Uploading")])
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.showWarningDialog = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "pt-2" }, [
                    _vm._v(
                      " Your file is currently uploading. If you close now, your upload may be lost."
                    )
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end px-5 py-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#00A9F4", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showWarningDialog = false
                            }
                          }
                        },
                        [_vm._v(" Return To Upload ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#3F51B5", dark: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("capture-upload-dialog-close")
                            }
                          }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showCaptureHelp,
            callback: function($$v) {
              _vm.showCaptureHelp = $$v
            },
            expression: "showCaptureHelp"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "elevation-0",
                  attrs: { dark: "", width: "100%", color: "#3F51B5" }
                },
                [
                  _c("v-toolbar-title", [_c("div", [_vm._v("Capture Help")])]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.showCaptureHelp = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-2" },
                [
                  _c("div", [
                    _vm._v(
                      " Learn how to easily take a screenshot or record your screen on an Apple, Android, or Windows device by tapping on a link below. "
                    )
                  ]),
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c("v-list-item-title", [_vm._v("Apple")]),
                      _c("v-list-item", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://support.apple.com/en-us/HT207935",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Record iPhone or iPad Screen")]
                        )
                      ]),
                      _c("v-list-item", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://support.apple.com/en-us/HT200289",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Take a screenshot on your iPhone")]
                        )
                      ]),
                      _c("v-list-item", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://support.apple.com/en-us/HT210781",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Take a screenshot on your iPad")]
                        )
                      ]),
                      _c("v-list-item-title", [_vm._v("Android")]),
                      _c("v-list-item", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://support.google.com/android/answer/9075928?hl=en#:~:text=Record%20your%20phone%20screen,record%20to%20your%20Quick%20Settings.",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "Take a screenshot or record screen on Android"
                            )
                          ]
                        )
                      ]),
                      _c("v-list-item-title", [_vm._v("Windows")]),
                      _c("v-list-item", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://support.microsoft.com/en-us/surface/take-a-screenshot-or-record-your-screen-on-surface-81be94a3-fd33-16e1-ac2a-cb4668326566",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "Take a screenshot or record screen on Surface, PC, or laptop"
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }