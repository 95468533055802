import axios from "axios";
import router from "@/router";
import store from "@/store";
import UNAUTHENTICATED_ROUTES from "@/constants/unauthenticatedRoutes";
import LogRocket from "logrocket";

const APIURL = process.env.VUE_APP_API_URL;

const axiosWithRegularAuth = axios.create();
const axiosWithJwtAuth = axios.create();
const axiosWithNoAuth = axios.create();

axiosWithRegularAuth.interceptors.request.use(
  (config) => {
    if (!config.url.includes("get_token")) {
      config.headers["X-AUTH"] = localStorage.getItem("token");
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const URLS_TO_IGNORE = ["refresh_token", "esri_login", "get_client_id"];

axiosWithRegularAuth.interceptors.response.use(null, async (error) => {
  if (UNAUTHENTICATED_ROUTES.includes(router.currentRoute.name)) {
    if (router.currentRoute.name === "Login") {
      return Promise.reject(error);
    }
    return;
  }
  if (error?.response?.status === 500) {
    store.commit("setErrorOccurred", true);
    try {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (auth?.log_activity) {
        LogRocket.captureMessage("Error: 500", {
          extra: {
            errorType: "500 Error",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  } else if (error?.response?.status === 400) {
    if (
      URLS_TO_IGNORE.some((u) => error.response?.config?.url?.includes?.(u))
    ) {
      return Promise.reject(error);
    }

    if (Array.isArray(error.response?.data?.error)) {
      store.commit("setBadRequestError", error.response?.data?.error);
    } else if (typeof error.response?.data?.error === "string") {
      store.commit("setBadRequestError", [error.response?.data?.error]);
    }
  } else if (error?.response?.status === 403) {
    store.commit("setForbiddenError", error.response?.data?.error);
  } else if (error?.response?.status === 401) {
    try {
      const data = await axios.post(`${APIURL}/auth/refresh_token`, {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      const {
        data: {
          results: { token },
        },
      } = data;
      localStorage.setItem("auth", JSON.stringify(data.data.results));
      localStorage.setItem("token", token);
      return axios.request(error.config);
    } catch (error) {
      const portalUrl = localStorage.getItem("portal-url");
      const layerVisibilitySettings = localStorage.getItem(
        "layer-visibility-settings"
      );
      localStorage.clear();
      localStorage.setItem("portal-url", portalUrl);
      localStorage.setItem(
        "layer-visibility-settings",
        layerVisibilitySettings
      );
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
});

axiosWithJwtAuth.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "shared-site-token"
    )}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosWithJwtAuth.interceptors.response.use(null, async (error) => {
  if (!["SharedSite", "Sites"].includes(router.currentRoute.name)) {
    return;
  }
  if (error?.response?.status === 500) {
    store.commit("setErrorOccurred", true);
    try {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (auth?.log_activity) {
        LogRocket.captureMessage("Error: 500", {
          extra: {
            errorType: "500 Error",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  } else if (error?.response?.status === 400) {
    if (Array.isArray(error.response?.data?.error)) {
      store.commit("setBadRequestError", error.response?.data?.error);
    } else if (
      typeof error.response?.data?.error === "string" &&
      !error.response?.config?.url?.includes("refresh_token") &&
      !error.response?.config?.url?.includes("esri_login") &&
      !error.response?.config?.url?.includes("esri_login") &&
      !error.response?.config?.url?.includes("get_client_id")
    ) {
      store.commit("setBadRequestError", [error.response?.data?.error]);
    }
  } else if ([401, 403].includes(error?.response?.status)) {
    store.commit("setForbiddenError", true);
  }
  return Promise.reject(error);
});

export { axiosWithRegularAuth, axiosWithJwtAuth, axiosWithNoAuth };
