<template>
  <v-dialog :value="showBadRequestErrorDialog" max-width="500px" persistent>
    <v-card>
      <v-card-text class="py-3">
        <p>An Error occurred.</p>

        <p v-if="!$route.path.includes('scheduledForms')">
          Error create the Citywork activity. The UtiliSync form cannot be
          started without the Cityworks activity being created.
        </p>

        <ul class="mx-0">
          <li v-for="e of badRequestError" :key="e">{{ e }}</li>
        </ul>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text color="#3F51B5" @click="setBadRequestError(undefined)">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "BadRequestErrorDialog",
  props: {
    showBadRequestErrorDialog: Boolean,
  },
  computed: {
    ...mapGetters(["badRequestError"]),
  },
  methods: {
    ...mapMutations(["setBadRequestError"]),
  },
};
</script>
