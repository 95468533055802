import { mapGetters, mapMutations } from "vuex";
import { chunk } from "lodash";
import { db } from "./utilisync-db";
import { axiosWithRegularAuth } from "@/plugins/axios";
import moment from "moment";
import axios from "axios";

const cancelTokenSource = axios.CancelToken.source();
const APIURL = process.env.VUE_APP_API_URL;

const removeDeletedFormDefs = async (formDefsSummary) => {
  if (formDefsSummary.length === 0) {
    return;
  }
  const allFormDefinitions = await db.formDefinitions.toCollection().toArray();
  const formDefSummaryIds = formDefsSummary.map((f) => f.form_definition_id);
  const formDefinitionsNotInSummary = allFormDefinitions.filter(
    (f) => !formDefSummaryIds.includes(f.form_definition_id)
  );
  for (const f of formDefinitionsNotInSummary) {
    await db.formDefinitions.delete(f.form_definition_id);
  }
};

const removeDeletedMapServices = async (mapServiceSummary) => {
  if (mapServiceSummary.length === 0) {
    return;
  }
  const mapServiceResults = await db.mapServices.toCollection().toArray();
  for (const { map_service_id: mapServiceId } of mapServiceResults) {
    const mapServiceInSummary = mapServiceSummary.find(
      (ms) => ms.map_service_id === mapServiceId
    );
    if (!mapServiceInSummary) {
      await db.mapServices.delete(mapServiceId);
    }
  }
};

const deleteExistingUserDataFieldsNotInSummary = async (
  userDataFieldSummary
) => {
  if (userDataFieldSummary.length === 0) {
    return;
  }
  const allExistingResults = await db.userDataFields.toCollection().toArray();
  for (const { user_data_field_id: userDataFieldId } of allExistingResults) {
    const inSummary = userDataFieldSummary.find(
      (udf) => udf.user_data_field_id === userDataFieldId
    );
    if (!inSummary) {
      await db.userDataFields.delete(userDataFieldId);
    }
  }
};

const deleteExistingUserDataValuesNotInSummary = async (
  userDataValueSummary
) => {
  if (userDataValueSummary.length === 0) {
    return;
  }
  const allExistingResults = await db.userDataValues.toCollection().toArray();
  for (const { user_data_value_id: userDataValueId } of allExistingResults) {
    const inSummary = userDataValueSummary.find(
      (udf) => udf.user_data_value_id === userDataValueId
    );
    if (!inSummary) {
      await db.userDataValues.delete(userDataValueId);
    }
  }
};

const removeDeletedUsers = async (usersSummary) => {
  if (usersSummary.length === 0) {
    return;
  }
  const userResults = await db.users.toCollection().toArray();
  for (const { user_id: userId } of userResults) {
    const userInSummary = usersSummary.find((u) => u.user_id === userId);
    if (!userInSummary) {
      await db.users.delete(userId);
    }
  }
};

export default {
  data() {
    return {
      downloadingMaps: false,
      downloadingMapServices: false,
      downloadingUsers: false,
      downloadingFormDefinitions: false,
      downloadingGisDataFieldOptions: false,
      downloadingMarkupSymbols: false,
      downloadingUserDataFields: false,
      downloadingUserDataValues: false,
      downloadingTasks: false,
      downloadingTaskTypes: false,
    };
  },
  computed: {
    ...mapGetters(["downloadSummaryData"]),
  },
  methods: {
    ...mapMutations(["setDownloadSummary"]),
    async downloadData(getLastUpdated) {
      await this.getDownloadSummary(getLastUpdated);
      await this.$nextTick();
      await this.downloadTasks();
      await this.downloadMaps(getLastUpdated);
      await this.downloadMapServices(getLastUpdated);
      await this.downloadUsers(getLastUpdated);
      await this.downloadFormDefinitions();
      await this.downloadMarkupSymbols();
      await this.downloadUserDataFields(getLastUpdated);
      await this.downloadUserDataValues(getLastUpdated);
      await this.downloadGisDataFields();
      await this.downloadGisDataValues();
      await this.downloadGisDataFieldOptions();
      if (!getLastUpdated) {
        localStorage.setItem("last-full-download-completed", new Date());
      }
      localStorage.setItem("last-updated", new Date().toString());
    },
    async getDownloadSummary(getLastUpdated) {
      if (!navigator.onLine) {
        return;
      }
      const lastUpdated = localStorage.getItem("last-updated");
      let lastUpdatedDate;
      if (getLastUpdated && lastUpdated) {
        lastUpdatedDate = moment
          .utc(moment(lastUpdated))
          .format("YYYY-MM-DD HH:mm:ss");
      }
      const downloadSummary = await axiosWithRegularAuth.get(
        `${APIURL}/field_reports_download_summary/complete`,
        {
          params: {
            last_updated_after: lastUpdatedDate,
          },
        }
      );
      this.setDownloadSummary(downloadSummary);
    },
    async downloadMaps(getLastUpdated) {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingMaps = true;
      const formDefsSummary =
        this.downloadSummaryData?.data?.results?.form_definitions ?? [];
      const formDefs = await db.formDefinitions.toCollection().toArray();
      const idsOfFormDefsToDownload = formDefsSummary
        .filter((f) => {
          const existingFormDefIds = formDefs.map(
            (ff) => ff.form_definition_id
          );
          const existingFormDef = formDefs.find(
            (ff) => ff.form_definition_id === f.form_definition_id
          );
          return (
            !existingFormDefIds.includes(f.form_definition_id) ||
            moment(existingFormDef?.last_updated).isBefore(
              moment(f?.last_updated)
            )
          );
        })
        .map((f) => f.form_definition_id);
      if (idsOfFormDefsToDownload.length > 0) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.post(
          `${APIURL}/form_definitions/by_form_definition_ids`,
          {
            form_definition_ids: idsOfFormDefsToDownload,
          },
          { cancelToken: cancelTokenSource.token }
        );
        await db.formDefinitions.bulkPut(results);
      }

      if (!getLastUpdated) {
        await removeDeletedFormDefs(formDefsSummary);
      }
      this.downloadingMaps = false;
    },
    async downloadMapServices(getLastUpdated) {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingMapServices = true;
      const mapServices =
        this.downloadSummaryData?.data?.results?.map_services ?? [];
      for (const {
        last_updated: lastUpdated,
        map_service_id: mapServiceId,
      } of mapServices) {
        try {
          const [existingMapService] = await db.mapServices
            .filter((ms) => ms.map_service_id === mapServiceId)
            .toArray();
          if (
            !existingMapService ||
            moment(existingMapService.last_updated).isBefore(
              moment(lastUpdated)
            )
          ) {
            const {
              data: { results },
            } = await axiosWithRegularAuth.get(
              `${APIURL}/map_services/${mapServiceId}`,
              {
                cancelToken: cancelTokenSource.token,
              }
            );
            const { service_type: serviceType } = results;
            if (serviceType !== "L") {
              await db.mapServices.put(results);
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.downloadingMapServices = false;
        }
      }

      if (!getLastUpdated) {
        await removeDeletedMapServices(mapServices);
      }
      this.downloadingMapServices = false;
    },
    async downloadUsers(getLastUpdated) {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingUsers = true;
      const usersSummary = this.downloadSummaryData?.data?.results?.users ?? [];
      const users = await db.users.toCollection().toArray();
      const idOfUsersToDownload = usersSummary
        .filter((u) => {
          const existingUserIds = users.map((uu) => uu.user_id);
          const existingUser = users.find((uu) => uu.user_id === u.user_id);
          return (
            !existingUserIds.includes(u.user_id) ||
            moment(existingUser?.last_updated).isBefore(moment(u?.last_updated))
          );
        })
        .map((u) => u.user_id);
      if (idOfUsersToDownload.length > 0) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.post(
          `${APIURL}/users_by_ids`,
          {
            user_ids: idOfUsersToDownload,
          },
          {
            cancelToken: cancelTokenSource.token,
          }
        );
        await db.users.bulkPut(results);
      }

      if (!getLastUpdated) {
        await removeDeletedUsers(usersSummary);
      }
      this.downloadingUsers = false;
    },
    async downloadFormDefinitions() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingFormDefinitions = true;
      const formDefSummary =
        this.downloadSummaryData?.data?.results?.form_definitions ?? [];
      const formDefinitions = await db.formDefinitions.toCollection().toArray();
      const idsOfFormDefinitionToDownload = formDefSummary.filter((fd) => {
        const existingFormDefinitionIds = formDefinitions.map(
          (fd) => fd.form_definition_id
        );
        const existingFormDefinition = formDefinitions.find(
          (efd) => fd.form_definition_id === efd.form_definition_id
        );
        return (
          !existingFormDefinitionIds.includes(fd.form_definition_id) ||
          moment(existingFormDefinition?.last_updated).isBefore(
            moment(fd?.last_updated)
          )
        );
      });
      try {
        const {
          data: { results: formDefinitions },
        } = await axiosWithRegularAuth.post(
          `${APIURL}/form_definitions/by_form_definition_ids`,
          {
            form_definition_ids: idsOfFormDefinitionToDownload,
          },
          {
            cancelToken: cancelTokenSource.token,
          }
        );
        await db.formDefinitions.bulkPut(formDefinitions);
      } catch (error) {
        console.log(error);
      }
      this.downloadingFormDefinitions = false;
    },
    async downloadMarkupSymbols() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingMarkupSymbols = false;
      const markupSymbols =
        this.downloadSummaryData?.data?.results?.markup_symbols ?? [];
      const downloadGisDataValuesPromises = markupSymbols.map(async (m) => {
        const { markup_symbol_id: markupSymbolId } = m;
        try {
          const { data: markupSymbol } = await axiosWithRegularAuth.get(
            `${APIURL}/markup_symbols/${markupSymbolId}`,
            {
              cancelToken: cancelTokenSource.token,
            }
          );
          await db.markupSymbols.put(markupSymbol);
        } catch (error) {
          console.log(error);
        }
      });
      const downloadGisDataValuesPromiseChunks = chunk(
        downloadGisDataValuesPromises,
        10
      );
      for (const downloadGisDataValuesPromises of downloadGisDataValuesPromiseChunks) {
        await Promise.all(downloadGisDataValuesPromises);
      }
      this.downloadingMarkupSymbols = false;
    },
    async downloadUserDataFields(getLastUpdated) {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingUserDataFields = true;
      const userDataFieldSummary =
        this.downloadSummaryData?.data?.results?.user_data_fields ?? [];
      const userDataFields = await db.userDataFields.toCollection().toArray();
      const idsOfUserDataFieldsToDownload = userDataFieldSummary
        .filter((udf) => {
          const existingUserDataFieldIds = userDataFields.map(
            (udff) => udff.user_data_field_id
          );
          const existingUserDataField = userDataFields.find(
            (udff) => udff.user_data_field_id === udf.user_data_field_id
          );
          return (
            !existingUserDataFieldIds.includes(udf.user_data_field_id) ||
            moment(existingUserDataField?.last_updated).isBefore(
              moment(udf?.last_updated)
            )
          );
        })
        .map((udf) => udf.user_data_field_id);
      if (idsOfUserDataFieldsToDownload.length > 0) {
        const {
          data: { results: userDataFieldResults },
        } = await axiosWithRegularAuth.post(
          `${APIURL}/user_data_fields/by_user_data_field_ids`,
          {
            user_data_field_ids: idsOfUserDataFieldsToDownload,
          }
        );
        await db.userDataFields.bulkPut(userDataFieldResults);
      }

      if (!getLastUpdated) {
        await deleteExistingUserDataFieldsNotInSummary(userDataFieldSummary);
      }
      this.downloadingUserDataFields = false;
    },
    async downloadUserDataValues(getLastUpdated) {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingUserDataValues = true;
      const userDataValueSummary =
        this.downloadSummaryData?.data?.results?.user_data_values ?? [];
      const userDataValues = await db.userDataValues.toCollection().toArray();
      const idsOfUserDataValuesToDownload = userDataValueSummary
        .filter((udv) => {
          const existingUserDataValueIds = userDataValues.map(
            (udvv) => udvv.user_data_value_id
          );
          const existingUserDataValue = userDataValues.find(
            (udvv) => udvv.user_data_value_id === udv.user_data_value_id
          );
          return (
            !existingUserDataValueIds.includes(udv.user_data_value_id) ||
            moment(existingUserDataValue?.last_updated).isBefore(
              moment(udv?.last_updated)
            )
          );
        })
        .map((udv) => udv.user_data_value_id);
      if (idsOfUserDataValuesToDownload.length > 0) {
        const {
          data: { results: userDataValueResults },
        } = await axiosWithRegularAuth.post(
          `${APIURL}/user_data_values/by_user_data_value_ids`,
          {
            user_data_value_ids: idsOfUserDataValuesToDownload,
          }
        );
        await db.userDataValues.bulkPut(userDataValueResults);
      }

      if (!getLastUpdated) {
        await deleteExistingUserDataValuesNotInSummary(userDataValueSummary);
      }
      this.downloadingUserDataValues = false;
    },
    async downloadTasks() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingTasks = true;
      const tasksSummary = this.downloadSummaryData?.data?.results?.tasks ?? [];
      const tasks = await db.tasks.toCollection().toArray();

      const taskIds = tasksSummary
        .filter((t) => {
          const existingTaskIds = tasks.map((t) => t.task_id);
          const existingTask = tasks.find((et) => et.task_id === t.task_id);
          return (
            !existingTaskIds.includes(t.task_id) ||
            moment(existingTask?.last_updated).isBefore(moment(t?.last_updated))
          );
        })
        .map((f) => f.form_definition_id);
      const {
        data: { results: newTasks },
      } = await axiosWithRegularAuth.post(`${APIURL}/tasks/by_task_ids`, {
        task_ids: taskIds,
      });
      db.tasks.bulkPut(newTasks);
      this.downloadingTasks = false;
    },
    async downloadGisDataFields() {
      if (!navigator.onLine) {
        return;
      }
      const gisDataFieldsSummary =
        this.downloadSummaryData?.data?.results?.gis_data_fields ?? [];
      if (gisDataFieldsSummary.length === 0) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.post(`${APIURL}/gis_data_fields_by_ids`, {
        ids: gisDataFieldsSummary.map((g) => g.gis_data_field_id),
      });
      await db.gisDataFields.bulkPut(results);
    },
    async downloadGisDataValues() {
      if (!navigator.onLine) {
        return;
      }
      const gisDataValuesSummary =
        this.downloadSummaryData?.data?.results?.gis_data_values ?? [];
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const existingGisDataValueIds = gisDataValues.map(
        (g) => g.gis_data_value_id
      );
      const idsOfGisDataValuesToDownload = gisDataValuesSummary
        .filter((g) => {
          const existingGisDataValue = gisDataValues.find(
            (gg) => gg.gis_data_value_id === g.gis_data_value_id
          );
          return (
            !existingGisDataValueIds.includes(g.gis_data_value_id) ||
            moment(existingGisDataValue?.last_updated).isBefore(
              moment(g?.last_updated)
            )
          );
        })
        .map((g) => g.gis_data_value_id);
      if (idsOfGisDataValuesToDownload.length === 0) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.post(`${APIURL}/gis_data_values`, {
        gis_data_value_ids: idsOfGisDataValuesToDownload,
      });
      await db.gisDataValues.bulkPut(results);
    },
    async downloadAuthObject() {
      if (!navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.post(`${APIURL}/auth/get_auth_object`, {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      localStorage.setItem("auth", JSON.stringify(results));
    },
    async downloadGisDataFieldOptions() {
      if (!navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/gis_data_field_options/download`
      );
      await db.gisDataFieldOptions.bulkPut(results);
    },
  },
};
