import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "@/store";

const cancelTokenSource = axios.CancelToken.source();

Vue.use(VueRouter);

const beforeEnter = (to, from, next) => {
  const refreshToken = localStorage.getItem("refresh_token");
  cancelTokenSource.cancel();
  store.commit("setMenuState", false);
  store.commit("setSharedSiteSignedOut", false);
  if (to.name !== "Login" && !refreshToken) {
    return next({ name: "Login", query: { redirectUrl: to.fullPath } });
  }
  return next();
};

const beforeEnterNoAuth = (to, from, next) => {
  store.commit("setMenuState", false);
  store.commit("setSharedSiteSignedOut", false);
  return next();
};

const routes = [
  {
    path: "/list",
    name: "List",
    component: () => import("@/views/List"),
    beforeEnter,
  },
  {
    path: "/scheduledForms",
    name: "Tasks",
    component: () => import("@/views/Tasks"),
    beforeEnter,
  },
  {
    path: "/map/:type?",
    name: "Map",
    component: () => import("@/views/MapView"),
    beforeEnter,
  },
  {
    path: "/sites",
    name: "Sites",
    component: () => import("@/views/Sites"),
    beforeEnter: beforeEnterNoAuth,
  },
  {
    name: "Login",
    path: "/login",
    component: () => import("@/views/Login"),
  },
  {
    name: "CreatePassword",
    path: "/createPassword",
    component: () => import("@/views/Login"),
    beforeEnter: beforeEnterNoAuth,
  },
  {
    name: "SignUp",
    path: "/signup",
    component: () => import("@/views/SignUp"),
    beforeEnter: beforeEnterNoAuth,
  },
  {
    name: "VersionLog",
    path: "/versionLog",
    component: () => import("@/views/VersionLog"),
    beforeEnter,
  },
  {
    name: "Site",
    path: "/site",
    component: () => import("@/views/Site"),
    beforeEnter,
  },
  {
    name: "SharedSite",
    path: "/sharedSite",
    component: () => import("@/views/SharedSite"),
    beforeEnter: beforeEnterNoAuth,
  },
  {
    name: "ActionItems",
    path: "/actionItems",
    component: () => import("@/views/ActionItems"),
    beforeEnter,
  },
  {
    name: "FormSubmission",
    path: "/formSubmission",
    component: () => import("@/views/FormSubmission"),
    beforeEnter,
  },
  {
    name: "Upload",
    path: "/upload",
    component: () => import("@/views/Upload"),
    beforeEnter,
  },
  {
    name: "EsriRedirect",
    path: "/esriRedirect",
    component: () => import("@/views/EsriRedirect"),
    beforeEnter: beforeEnterNoAuth,
  },
  {
    name: "Captures",
    path: "/captures",
    component: () => import("@/views/Captures"),
    beforeEnter: beforeEnter,
  },
  {
    name: "Capture",
    path: "/capture/:fileId",
    component: () => import("@/views/Capture"),
    beforeEnter: beforeEnterNoAuth,
  },
  // If new page is a no auth page it needs to be above PublicSite
  {
    name: "PublicSite",
    path: "/:urlId",
    component: () => import("@/views/PublicSite"),
    beforeEnter: beforeEnterNoAuth,
  },
  {
    path: "/",
    redirect: {
      name: "Map",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
