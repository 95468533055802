<template>
  <v-dialog v-model="showCaptureOrUploadDialog" max-width="500px" persistent>
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <v-toolbar-title>
          <div>Capture or Upload</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="tryClose()">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="uploadPercentage < 100" class="pa-3">
        <div>
          Use this tool to create a screen recording or upload an existing file
          (image or video) to send to UtiliSync Support.
        </div>
        <div v-if="!isMobile">
          <div class="text-subtitle-1 mt-4">Take a screen recording.</div>
          <div class="d-flex justify-center mt-2">
            <v-btn
              class="white--text"
              color="#3F51B5"
              @click="$emit('open-capture-toolbar')"
              :disabled="isUploading"
              >Open Capture Tool</v-btn
            >
          </div>
          <div class="my-4 text-h6" style="text-align: center">OR</div>
        </div>
        <div class="d-flex">
          <div class="text-subtitle-1">
            Upload captured screenshot or video.
          </div>
          <div class="mt-n1 ml-2">
            <v-btn icon color="#00A9F4" @click="showCaptureHelp = true">
              <v-icon>
                {{ mdiInformation }}
              </v-icon></v-btn
            >
          </div>
        </div>
        <div class="d-flex justify-center">
          <file-pond
            name="files"
            ref="files"
            label-idle="<span class='filepond--label-action'> Browse</span> or drop photo or video here..."
            accepted-file-types="image/heic, image/jpeg, image/png, video/mp4, video/mov, video/webm"
            fileValidateTypeLabelExpectedTypes="Allows .png, .jpg, .heic, .mp4, .webm, .mov"
            :files="uploadedFile"
            class="mt-2 cursor-pointer"
            style="width: 400px"
            @addfile="uploadFile()"
          />
        </div>
        <div class="mt-2">
          <div class="d-flex justify-center" v-if="isUploading">
            <v-progress-linear
              v-model="uploadPercentage"
              color="#00A9F4"
              height="25"
              rounded
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </div>
          <div v-else style="height: 25px"></div>
        </div>
      </v-card-text>
      <v-card-text v-else class="pa-4">
        <div>
          The capture link has been created. Copy the capture link below and
          send to UtiliSync Support.
        </div>
      </v-card-text>
      <v-card-actions
        v-if="uploadPercentage < 100"
        class="d-flex justify-end px-5 pt-0 pb-3"
      >
        <v-btn color="#00A9F4" text :to="`/captures`" target="_blank"
          >View Captures <v-icon class="ml-2">{{ mdiOpenInNew }}</v-icon></v-btn
        >
      </v-card-actions>
      <v-card-actions v-else class="d-flex justify-end px-5 pt-0 pb-3">
        <v-btn color="#00A9F4" text :to="`${uploadedLink}`" target="_blank">
          <v-icon class="mr-2">{{ mdiOpenInNew }}</v-icon> View</v-btn
        >
        <v-btn color="#3F51B5" dark @click="copyUploadLink()" class="ml-2">
          <v-icon class="mr-2">{{ mdiContentCopy }}</v-icon
          >Copy</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="showCopiedLinkSnack" :timeout="5000">
      Copied upload link!
    </v-snackbar>
    <v-dialog
      v-model="showWarningDialog"
      v-if="isUploading"
      persistent
      max-width="450px"
    >
      <v-card>
        <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
          <v-toolbar-title>
            <div>Currently Uploading</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showWarningDialog = false">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-2">
          Your file is currently uploading. If you close now, your upload may be
          lost.</v-card-text
        >
        <v-card-actions class="d-flex justify-end px-5 py-3">
          <v-btn color="#00A9F4" @click="showWarningDialog = false" text>
            Return To Upload
          </v-btn>
          <v-btn
            color="#3F51B5"
            @click="$emit('capture-upload-dialog-close')"
            dark
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCaptureHelp" persistent max-width="600px">
      <v-card>
        <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
          <v-toolbar-title>
            <div>Capture Help</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showCaptureHelp = false">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-2">
          <div>
            Learn how to easily take a screenshot or record your screen on an
            Apple, Android, or Windows device by tapping on a link below.
          </div>
          <v-list dense>
            <v-list-item-title>Apple</v-list-item-title>
            <v-list-item>
              <a href="https://support.apple.com/en-us/HT207935" target="_blank"
                >Record iPhone or iPad Screen</a
              >
            </v-list-item>
            <v-list-item>
              <a href="https://support.apple.com/en-us/HT200289" target="_blank"
                >Take a screenshot on your iPhone</a
              >
            </v-list-item>
            <v-list-item>
              <a href="https://support.apple.com/en-us/HT210781" target="_blank"
                >Take a screenshot on your iPad</a
              >
            </v-list-item>
            <v-list-item-title>Android</v-list-item-title>
            <v-list-item>
              <a
                href="https://support.google.com/android/answer/9075928?hl=en#:~:text=Record%20your%20phone%20screen,record%20to%20your%20Quick%20Settings."
                target="_blank"
                >Take a screenshot or record screen on Android</a
              >
            </v-list-item>
            <v-list-item-title>Windows</v-list-item-title>
            <v-list-item>
              <a
                href="https://support.microsoft.com/en-us/surface/take-a-screenshot-or-record-your-screen-on-surface-81be94a3-fd33-16e1-ac2a-cb4668326566"
                target="_blank"
                >Take a screenshot or record screen on Surface, PC, or laptop</a
              >
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiOpenInNew,
  mdiContentCopy,
  mdiInformation,
} from "@mdi/js";
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;
const FRONT_END_URL = process.env.VUE_APP_FRONT_END_URL;

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

export default {
  name: "CaptureOrUploadDialog",
  components: {
    FilePond,
  },
  props: {
    showCaptureOrUploadDialog: Boolean,
  },
  data() {
    return {
      mdiClose,
      mdiOpenInNew,
      mdiContentCopy,
      mdiInformation,
      uploadedFile: undefined,
      uploadedLink: "",
      showCopiedLinkSnack: false,
      showWarningDialog: false,
      isUploading: false,
      uploadPercentage: 0,
      hasUploaded: false,
      showCaptureHelp: false,
    };
  },
  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? true
        : false;
    },
  },
  methods: {
    async uploadFile() {
      const fileObj = this.$refs.files.getFiles()[0];
      if (fileObj) {
        if (!this.isFileTypeAccepted(fileObj.file)) {
          return;
        }

        this.isUploading = true;

        const payload = new FormData();
        const splitFileName = fileObj.file.name.split(".");
        const extension = splitFileName[splitFileName.length - 1];
        payload.append("file", fileObj.file);
        payload.append("file_extension", extension);
        payload.append("app", "Docs");

        const config = {
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };

        const response = await axiosWithRegularAuth.post(
          `${APIURL}/captures`,
          payload,
          config
        );
        const { results } = response.data;
        this.uploadedLink = `/capture/${results.file_id}`;

        this.isUploading = false;
        this.copyUploadLink();
      }
    },
    async copyUploadLink() {
      await navigator.clipboard.writeText(
        `${FRONT_END_URL}${this.uploadedLink}`
      );
      this.showCopiedLinkSnack = true;
    },
    tryClose() {
      if (this.isUploading) {
        this.showWarningDialog = true;
      } else {
        this.$emit("capture-upload-dialog-close");
      }
    },
    isFileTypeAccepted(file) {
      const acceptedFileTypes = [
        "image/heic",
        "image/jpeg",
        "image/png",
        "video/mp4",
        "video/mov",
        "video/webm",
      ];
      return acceptedFileTypes.includes(file.type);
    },
  },
};
</script>
