export default [
  {
    version: "0.66",
    date: "December 18, 2023",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Utilisync Field Not Updating (DEV-1281)",
        description:
          'Fixed an issue where the Utilisync Field wasn\'t updating. Now, it updates when the "Reload" button is clicked in the Info Panel.',
      },
      {
        type: "Bug",
        title: "2. Convert PDF to PNG Tool Breaking (DEV-1290)",
        description:
          "Fixed an issue where the Convert to PDF tool was not working. Now, PDF files will be converted successfully",
      },
      {
        type: "Bug",
        title: "3. Markup Tool Custom Symbols (DEV-1300)",
        description:
          "Fixed an issue where the Markup Tool for the custom symbol was not working. Now, users can customize a markup symbol.",
      },
    ],
  },
  {
    version: "0.65",
    date: "December 8, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Not Populating Reference Field (DEV-1245)",
        description:
          "Fixed an issue where the Reference Field tab was not populating in the Form Submissions page. Now, it is correctly displaying the Reference Field data.",
      },
    ],
  },
  {
    version: "0.64",
    date: "December 4, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Error Creating Cityworks Activities (DEV-882)",
        description:
          "Fixed an issue where an error message 'Error creating the Cityworks activity. The Utilisync form cannot be started without the Cityworks activity being created' appeared upon form submission.",
      },
      {
        type: "Bug",
        title: "2. 'Cannot Delete Task' Error Message (DEV-1261)",
        description:
          "Fixed an issue where the error message 'Cannot delete task. It is attached to a form submission' appeared in Form UtiliBots upon form submission.",
      },
    ],
  },
  {
    version: "0.63",
    date: "November 29, 2023",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Sign Out Issue (DEV-1269)",
        description:
          "Fixed an issue where users were getting logged out from the app when updating the Utilisync/GIS fields. Now, users can update these fields and not get logged out.",
      },
    ],
  },
  {
    version: "0.62",
    date: "November 27, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Task Not Closing when Submitting Final (DEV-1234)",
        description:
          "Fixed an issue where in some cases scheduled form tasks were not closing when it had been submitted final.",
      },
    ],
  },
  {
    version: "0.61",
    date: "November 16, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Add Feature to Feature Service (DEV-500)",
        description:
          "Fixed an issue where in some cases adding features to Feature Service layers did not work as expected and would show a different symbol than expected.",
      },
      {
        type: "Bug",
        title: "2. Info Panel GIS Date Field Issue (DEV-1223)",
        description:
          "Fixed an issue where date fields were saving the previous the day before when saved. Now, the correct date is set accurately.",
      },
      {
        type: "Bug",
        title: "3. Long Words Not Breaking (DEV-1123)",
        description:
          "Fixed an issue where long words are not breaking correctly in generated PDFs.",
      },
    ],
  },
  {
    version: "0.60",
    date: "November 10, 2023",
    summary: "Updates to an existing feature and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Faster Loading Form Submissions (DEV-1183)",
        description: "The Form Submissions page now loads faster.",
      },
      {
        type: "Bug",
        title: "2. Scheduled Forms Deleting Fix (DEV-1223)",
        description:
          "Fixed an issue where scheduled form tasks could be deleted when a form had been started on it.",
      },
      {
        type: "Bug",
        title: "3. Images Not Resizing (DEV-1232)",
        description:
          "Fixed an issue where images added to forms were not resizing as expected.",
      },
      {
        type: "Bug",
        title: "4. Last Updated on Form Submissions Page (DEV-1225)",
        description:
          "Fixed an issue where the 'Last Updated' column inb the Form Submissions page did not show the time and date to match the user's set time zone.",
      },
    ],
  },
  {
    version: "0.59",
    date: "November 6, 2023",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Added 'Reload' Button to Login Page (DEV-1230)",
        description:
          "Added 'Reload' button to the Login page so users can reload the app.",
      },
    ],
  },
  {
    version: "0.58",
    date: "October 31, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Main and Related Table UtiliBots 'Retry' Button (DEV-1189)",
        description:
          "Fixed an issue where the Main and Related Table UtiliBots had a 'Retry' button when these UtiliBots cannot be retried.",
      },
    ],
  },
  {
    version: "0.57",
    date: "October 18, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Displaying Incorrect Organization (DEV-1189)",
        description:
          "Fixed an issue where an incorrect organization on action items was showing in the generated PDF. Now, the correct organization is being displayed..",
      },
    ],
  },
  {
    version: "0.56",
    date: "October 10, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Main Table Update Issue (DEV-1171)",
        description:
          "Fixed an issue where the Esri Main Table was not updating as expected for certain feature service layer configurations.",
      },
      {
        type: "Bug",
        title: "2. Form Submission List Filter (DEV-892)",
        description:
          "Fixed an issue where some filter options on the Form Submission list page did not display as expected.",
      },
    ],
  },
  {
    version: "0.55",
    date: "October 5, 2023",
    summary: "Updates to an existing feature and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Added 'Hint' to Single and Multi Select Fields (DEV-1156)",
        description:
          "Single and Multi Select fields now show hints if added in the form field configuration.",
      },
      {
        type: "Bug",
        title: "2. Loading Form Submissions Page (DEV-1171)",
        description:
          "Fixed an issue where the Form Submissions page took a long time to load. Now, it loads quickly.",
      },
    ],
  },
  {
    version: "0.54",
    date: "September 29, 2023",
    summary: "Additional features as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Filter By Form Field (DEV-892)",
        description:
          "When filtering on the Form Submission page, users can now filter for a specific form field on the form to narrow down results.",
      },
      {
        type: "Feature",
        title: "2. UtiliBot Pop-up For Submitted Forms (DEV-875)",
        description:
          "Added the option to be able to enable automatic Utilibot pop-up after a form is submitted.",
      },
      {
        type: "Feature",
        title: "3. Site Information on Scheduled Forms List (DEV-869)",
        description:
          "Added the 'Site' (name) and 'Site Address' fields to the Scheduled Forms page.",
      },
    ],
  },
  {
    version: "0.53",
    date: "September 15, 2023",
    summary:
      "Updated Cityworks integration and fixed a bug as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Updated to Support Cityworks 23 (DEV-957 and DEV-1115)",
        description:
          "This represents a major update to the Cityworks integration. The entire Cityworks integration was revamped to support the new Cityworks 23 security requirements, while still supporting the older version of Cityworks as well.",
      },
      {
        type: "Bug",
        title: "2. Fixed Cityworks Observation Mappings (DEV-1121)",
        description: "Fixed an issue with Cityworks Observation Mappings.",
      },
    ],
  },
  {
    version: "0.52",
    date: "September 6, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. V2 Form Generation Issue (DEV-1112)",
        description:
          "Fixed an issue where formatting and row height was incorrect on generated PDFs.",
      },
    ],
  },
  {
    version: "0.51",
    date: "August 18, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Form Autosave Issues (DEV-1088)",
        description:
          "Fixed an issue where the form was not recognizing that changes were made to table, action item, and file fields and was not auto-saving as a result.",
      },
    ],
  },
  {
    version: "0.50",
    date: "August 17, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Task Due Dates Changing (DEV-1085)",
        description:
          "Fixed an issue where the date was formatted incorrectly when editing a scheduled form task from the list view causing the date to appear as the day before the actual due date.",
      },
    ],
  },
  {
    version: "0.49",
    date: "August 11, 2023",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Custom Basemaps (DEV-45)",
        description:
          "Added the ability to use custom basemaps. We support custom basemaps of the following types: Tile, Vector Tile, and WMS layers.",
      },
      {
        type: "Feature",
        title: "2. Login Updates (DEV-297)",
        description:
          "Added a dialog that provides a more detailed error when a user tries to login to an inactive user or to an inactive organization.",
      },
      {
        type: "Feature",
        title: "3. Added Title For Dialog (DEV-1067)",
        description:
          "Dialog for error that appears due to issues with ArcGIS token validation now has a title.",
      },
      {
        type: "Feature",
        title: "4. Added 'X' Button to ArcGIS Expiration Dialog (DEV-1069)",
        description:
          "Dialog that notifies the user that their ArcGIS authorization has expired now has an option to close the dialog so that the user is not forced to sign out or sign in.",
      },
      {
        type: "Bug",
        title: "5. Notification Template Smart Fields (DEV-1066)",
        description:
          "Fixed an issue with Notification Templates where the smart fields did not pull from the UtiliSync fields as expected.",
      },
      {
        type: "Bug",
        title: "6. Email Form Fields Not Saving as Expected (DEV-886)",
        description:
          "Fixed an issue where email form fields did not save to UtiliSync and GIS fields as expected with multiple emails added.",
      },
      {
        type: "Bug",
        title: "7. Action Item Due Dates (DEV-1071)",
        description:
          "Fixed an issue where the due dates for action items were not consistent throughout the app.",
      },
      {
        type: "Bug",
        title: "8. Info Panel Spacing (DEV-1074)",
        description:
          "Fixed an issue where the spacing of items in the info panel was incorrect for the GIS data with sections.",
      },
    ],
  },
  {
    version: "0.48",
    date: "July 17, 2023",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Site and Public Site Pages (DEV-566)",
        description:
          "Formatted the Site and Public Site pages for a better user experience.",
      },
    ],
  },
  {
    version: "0.47",
    date: "July 11, 2023",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Site Contact Emails (DEV-1024)",
        description:
          "Updated the emails that site contacts receive to include an updated link to the Docs app.",
      },
    ],
  },
  {
    version: "0.46",
    date: "July 7, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Reference Field Not Saving (DEV-589)",
        description:
          "Fixed an issue where the Reference Field was not being saved and displayed as intended on error notifications.",
      },
      {
        type: "Bug",
        title: "2. Form Loading Without Cityworks Token (DEV-1021)",
        description:
          "Fixed an issue where, when the Cityworks token expired, the form still appeared to load. Now, if the Cityworks token is expired, the user will get a warning and see the dialog to sign in.",
      },
    ],
  },
  {
    version: "0.45",
    date: "July 1, 2023",
    summary: "Updates to existing features and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Task Email Update (DEV-606)",
        description:
          "Updated the task email to better align with the action item email and to include more relevant links.",
      },
      {
        type: "Feature",
        title: "2. Added 'Select Open Task' Dialog (DEV-916)",
        description:
          "Added the 'Select Open Task' dialog to shared site pages that appears if a task exists that matches the form the user is trying to start.",
      },
      {
        type: "Feature",
        title: "3. Text Wrapping Issue in Info Panel (DEV-1004)",
        description:
          "Updated the formatting of the UtiliSync and GIS fields in the Info Panel for better readability.",
      },
      {
        type: "Bug",
        title: "4. Multiple Photo Bug For Version 2 Forms (DEV-1005)",
        description:
          "Fixed an issue where the form was not being successfully submitted when two or more images were added. Now, the form can be successfully submitted even with multiple images attached.",
      },
    ],
  },
  {
    version: "0.44",
    date: "June 22, 2023",
    summary: "Additional features and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Create Screen Recording Widget (DEV-925)",
        description:
          "Added a screen recording widget for users to capture and upload screen recordings. Additionally, videos and images and be uploaded directly from the app.",
      },
      {
        type: "Bug",
        title: "2. Not Matching Due Date (DEV-940)",
        description:
          "Fixed an issue where the due date in the 'Scheduled Forms' tab and the 'Task Panel' did not match. Now, both the 'Scheduled Forms' tab and the 'Task Panel' display the same due date.",
      },
      {
        type: "Bug",
        title: "3. Recurring Task Creation (DEV-988)",
        description:
          "Fixed an issue where the View UtiliBot displayed 'A matching task already exists, so an additional task was not created' despite no recurring task being created. Now, a recurring task will be created after completing a task.",
      },
    ],
  },
  {
    version: "0.43",
    date: "June 14, 2023",
    summary: "Additional feature and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title:
          "1. Added 'Open' button to 'Test Layer Connection Dialog' (DEV-967)",
        description:
          "Added an 'Open' button that allows users to launch the ArcGIS Service URL in the 'Test ArcGIS Layer Connection Dialog'.",
      },
      {
        type: "Feature",
        title: "2. Bug Uploading Photos (DEV-919)",
        description:
          "Fixed an issue where after adding an image all options to add images were disabled, preventing their use. Now, the buttons stay enabled for use.",
      },
      {
        type: "Bug",
        title: "3. Cityworks Links Not Working (DEV-644)",
        description:
          "Fixed an issue where the 'Office' and 'Respond' button links were not functioning correctly. Now, when clicked, the user will be directed to the correct page.",
      },
      {
        type: "Bug",
        title: "4. Decimal Problem on Number Fields (DEV-958)",
        description:
          "Fixed an issue where generated PDFs were not including numbers after the decimal point in a field. Now, PDFs accurately include decimal values.",
      },
      {
        type: "Feature",
        title: "5.Formatting Issue in the Notification Templates (DEV-966)",
        description:
          "Fixed issue where the Notification Template did not properly render the Smart Field content. Now, the Smart Field content is correctly displayed in the Notification Template.",
      },
    ],
  },
  {
    version: "0.42",
    date: "June 8, 2023",
    summary: "Updates to existing features and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Verify Cityworks Token (DEV-960)",
        description:
          "If the user's Cityworks token is not valid, then the user is signed out from Cityworks and their token is removed.",
      },
      {
        type: "Feature",
        title: "2. UtiliSync Global ID and Feature ID Field (DEV-26)",
        description:
          "Global ID and Feature ID UtiliSync fields now can be ordered with all other UtiliSync fields.",
      },
      {
        type: "Bug",
        title: "3. Fields on Map Service Layers Not Displaying (DEV-963)",
        description:
          "After the last update, fields on map service layers stopped displaying. Now, the fields display.",
      },
    ],
  },
  {
    version: "0.41",
    date: "June 6, 2023",
    summary: "Additional feature and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Info Panel Field Customizations (DEV-899)",
        description:
          "Updated Info Panel to follow a customized fields display set by admins in the Admin app. UtiliSync and GIS fields can now be ordered, hidden, and grouped in sections.",
      },
      {
        type: "Bug",
        title: "2. Display Site Contact's Organization in Form Email (DEV-910)",
        description:
          "Fixed an issue where where the name of the organization that created the action item was showing for site contacts instead of the site contacts' own organization name. Now, the site contacts' organization is displayed in the form email.",
      },
      {
        type: "Bug",
        title: "3. Home Extent (DEV-907)",
        description:
          "Fixed an issue where the zoom scale on the map was incorrect after clicking the home icon that takes the user to their set default extent on the map.",
      },
      {
        type: "Bug",
        title: "4. World View (DEV-908)",
        description:
          "Fixed an issue where the zoom scale was set incorrectly when the map was loaded and the user would be at the world view.",
      },
      {
        type: "Bug",
        title: "5. Layer Names Not Showing (DEV-943)",
        description:
          "Fixed an issue where layer names and symbols were not showing. Now, both the symbol and layer name are displayed correctly.",
      },
      {
        type: "Bug",
        title: "5. Form Error (DEV-948)",
        description:
          "Fixed an issue where an error occurs while opening a form and the form would still load.  Now, a dialog message is shown to have the user reach out to support.",
      },
    ],
  },
  {
    version: "0.40",
    date: "May 24, 2023",
    summary: "Updates to existing features and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Date Formatting (DEV-870)",
        description:
          "Updated date formatting across the app to display dates and times more appropriately.",
      },
      {
        type: "Feature",
        title: "2. Scheduled Forms List View (DEV-874)",
        description:
          "Updated the Scheduled Forms list view for a better user experience. Additionally, extra white space on bottom of the list view has been removed.",
      },
      {
        type: "Bug",
        title: "3. Multiple Email PDF Issue (DEV-915)",
        description:
          "Fixed an issue where the email fields with multiple emails input were displaying as an array with brackets with additional email address generated on the PDF. Now, the emails that were added are shown accurately and in the correct format.",
      },
      {
        type: "Bug",
        title: "4. Unknown Layer Type (DEV-432)",
        description:
          "Fixed an issue where a pop-up dialog displaying 'Unknown Layer Type' would appear when switching from Map to Layers view. The pop-up dialog no longer appears when switching between Map and Layers view.",
      },
      {
        type: "Bug",
        title: "5. Photo Field Description (DEV-575)",
        description:
          "Fixed an issue where the photo descriptions would not be expanded to fit the description.",
      },
    ],
  },
  {
    version: "0.39",
    date: "May 20, 2023",
    summary: "Additional features and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Cycle Through Multiple Selected Features (DEV-794)",
        description:
          "Added the functionality to cycle through features when multiple features are selected on the map. This enables easy navigation between the different selectable items on the map.",
      },
      {
        type: "Feature",
        title: "2. Email Notification of Action Item Assignment (DEV-526)",
        description:
          "Added the functionality to send email notifications whenever a form is submitted, containing the newly added action items. The email is sent directly to the assigned person for each action item.",
      },
      {
        type: "Bug",
        title: "3. Duplicate Future Tasks Generated (DEV-885)",
        description:
          "Fixed an issue where duplicate future tasks were being generated. Now, upon submitting the form, only a single future task will be created.",
      },
      {
        type: "Bug",
        title: "4. Action Item PDF Creation Bug (DEV-916)",
        description:
          "Fixed an issue where errors occurred for action items without associated photos. Now, even if a photo is not added to the Action Item, the generation of PDFs proceeds without any errors.",
      },
      {
        type: "Bug",
        title: "5. Rich Text Field Not Saving Correctly (DEV-890)",
        description:
          "Fixed an issue where rich text fields were not saving correctly if there was a comma in the text. Now, the rich text saves correctly.",
      },
    ],
  },
  {
    version: "0.38",
    date: "May 10, 2023",
    summary: "Updates to an existing feature and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Optimize Downloading Large Datasets (DEV-904)",
        description:
          "Improved downloading of large datasets. This impacts closed tickets and form submission downloads.",
      },
      {
        type: "Bug",
        title: "2. Bug Creating PDF - Action Item Error (DEV-909)",
        description:
          "Fixed an issue where errors were occurring while creating PDFs due to open action items. Now PDF creation is now functioning properly and without any errors.",
      },
      {
        type: "Bug",
        title: "3. Documents Not Visible in Info Panel (DEV-884)",
        description:
          "Fixed an issue where documents were not visible in the Info Panel when opened from the Scheduled Forms tab, while they were visible when opened from the Map tab. Now you can access the documents from the Info Panel.",
      },
      {
        type: "Bug",
        title: "4. Cityworks Dialog Showing on Page Load (DEV-883)",
        description:
          "Fixed an issue where a Cityworks dialog showed every time the page loaded when Cityworks was enabled for the organization, but the user wasn’t signed in. Now, the Cityworks token is checked when a form is started that has the Cityworks UtiliBot enabled.",
      },
      {
        type: "Bug",
        title: "5. 'Invalid Task ID' Dialog Issue (DEV-888)",
        description:
          "Fixed and issue where a dialog showed after a form was submitted if the task was already closed. Now the dialog doesn’t show if the task is already closed.",
      },
    ],
  },
  {
    version: "0.37",
    date: "May 6, 2023",
    summary: "Updates to an existing feature and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Check Esri Token Expiration (DEV-506)",
        description:
          "Added a watcher on the ArcGIS token. If a user signs in to UtiliSync using ArcGIS, the token is good for 2 weeks. Now, as soon as the token expires, a dialog appears prompting the user to sign in again. This will prevent users from submitting forms with expired tokens.",
      },
      {
        type: "Bug",
        title: "2. Forms Bugs Generating PDF (DEV-872)",
        description:
          "Fixed an error where submitting a form would result in a 'Create Form PDF' error. Users can now submit forms without encountering this error.",
      },
      {
        type: "Bug",
        title: "3. Multiple-Enabled Email fields not saving to GIS (DEV-787)",
        description:
          "Fixed an error where only the first email address entered in a field that was set to save to GIS would be saved. Users can now enter multiple email addresses and have all of them saved correctly.",
      },
    ],
  },
  {
    version: "0.36",
    date: "May 3, 2023",
    summary:
      "Additional feature, updates to an existing feature, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Save Layer Visibility (DEV-675)",
        description:
          "Added the functionality to save layer visibility for a map in local storage. This means if a layer is turned off and the map is reloaded, that layer will remain turned off.",
      },
      {
        type: "Feature",
        title: "2. Updated Add Feature Panel (DEV-880)",
        description:
          "Updated Add Feature panel with improved design and functionality.",
      },
      {
        type: "Bug",
        title: "3. Action Item PDF Bugs (DEV-856)",
        description:
          "Fixed an issue where the Due Date and Photos from Action Items were not being inserted on the PDF upon form submission. As a result of the fix, the Due Date and Photos from Action Items are now correctly inserted in the PDF upon form submission.",
      },
      {
        type: "Bug",
        title: "4. Problem seeing documents on iPhone/iPad (DEV-860)",
        description:
          "Fixed issue where documents disappeared when client scrolled on iPhone/iPad public site. Client can now scroll without issue.",
      },
    ],
  },
  {
    version: "0.35",
    date: "April 27, 2023",
    summary: "Updates to an existing feature and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. ArcGIS Version 4.26",
        description:
          "Updated to the latest version (4.26) of the ArcGIS Maps SDK for JavaScript.",
      },
      {
        type: "Bug",
        title: "2. Reload App Offline (DEV-858)",
        description:
          "Fixed an issue where the app would not load if it was opened or reloaded while offline. Now, the app can be reloaded and opened while offline.",
      },
      {
        type: "Bug",
        title: "3. Bug Cancelling Action Items (DEV-834)",
        description:
          "The issue where users were unable to delete items under Action Items has been resolved. As a result, users can now easily delete Action Items from the list without encountering any issues.",
      },
      {
        type: "Bug",
        title: "4. Update How to Add Photos to Forms and Sites (DEV-610)",
        description:
          "Fixed and issue where iOS devices would sometimes crash when adding photos to forms and sites. Now, the way photos are added to forms and sites works with iOS devices. Photo thumbnails were also updated.",
      },
    ],
  },
  {
    version: "0.34",
    date: "April 14, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Sign-In Screen Display Issue (DEV-814)",
        description:
          "Fixed an issue where the sign-in screen was stretched on iPad Air (2019).",
      },
      {
        type: "Bug",
        title: "2. Default Values Issue (DEV-803)",
        description:
          "Fixed an issue where fields with default values, if cleared, would still have its default value upon form submission.",
      },
      {
        type: "Bug",
        title: "3. Site Contacts (DEV-831)",
        description:
          "Fixed an issue where the user was unable to include special characters in the fields to create or update a site contact.",
      },
      {
        type: "Bug",
        title: "4. GIS Layer List View (DEV-48)",
        description:
          "Fixed multiple issues with the GIS layer list view. Aliases are shown if available and filters now work as expected.",
      },
      {
        type: "Bug",
        title: "5. GIS Layer List View (DEV-739)",
        description:
          "Fixed and issue where the expanded info panel wasn't getting updated after a form was submitted.",
      },
    ],
  },
  {
    version: "0.33",
    date: "March 31, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "Cityworks Link in Form (DEV-644)",
        description:
          "Fixed the link to Cityworks work orders and inspections in forms.",
      },
      {
        type: "Bug",
        title: "Cityworks Insepction Observations (DEV-764)",
        description:
          "Fixed an issue where Cityworks inspection observations weren't being updated for forms that were created in the User Admin app.",
      },
    ],
  },
  {
    version: "0.32",
    date: "March 28, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Site Contact Creation Issue (DEV-816)",
        description:
          "Fixed an issue where the user was unable to create site contacts.",
      },
      {
        type: "Bug",
        title: "2. Tasks Issues (DEV-781)",
        description:
          "Fixed an issue where the latest tasks were not being downloaded which prevented new created tasks from showing.",
      },
      {
        type: "Bug",
        title: "3. Error Message When Submitting Form (DEV-804)",
        description:
          'Fixed an issue where users were seeing a "This form is already submitted" dialog when they tried to submit a form.',
      },
    ],
  },
  {
    version: "0.31",
    date: "March 21, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Expanded Info Panel Data (DEV-739)",
        description:
          "Fixed an issue where the information on the expanded info panel did not get reloaded after a form submission.",
      },
      {
        type: "Bug",
        title: "2. Info Panel Not Showing Docs (DEV-738)",
        description:
          "Fixed an issue where if the user opened the Info Panel from the Action Items list view there were no documents that appeared in the Docs pane.",
      },
      {
        type: "Bug",
        title: "3. Issues with Tasks (DEV-781)",
        description:
          "Fixed several issues related to tasks, including: fixed the task counter on the info panel, added a <b>RELOAD</b> button to the task pane to allow user to get the latest tasks, and fixed an issue related to conditionally creating tasks.",
      },
      {
        type: "Bug",
        title: "4. Scheduled Forms List (DEV-765)",
        description:
          "Fixed an issue where scheduled form tasks did not clear from the Scheduled Forms list view.",
      },
      {
        type: "Bug",
        title: "5. PDF Generation Issues (DEV-770)",
        description:
          "Fixed an issue where a new change to form generation broke existing forms in certain cases.",
      },
    ],
  },
  {
    version: "0.30",
    date: "March 10, 2023",
    summary: "Additional feature and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Reload UtiliSync Layer Button",
        description:
          "Added button that allows the user to manually reload the info panel and layer.",
      },
      {
        type: "Bug",
        title: "2. Form Section Not Opening By Default",
        description:
          "Fixed an issue where sections of the form did not open by default if set.",
      },
    ],
  },
  {
    version: "0.29",
    date: "February 24, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Task Due Dates",
        description:
          "Fixed an issue where task due dates were showing the day before the task was due.",
      },
      {
        type: "Bug",
        title: "2. Password Reset",
        description:
          "Fixed an issue where resetting a password did not work for some users.",
      },
    ],
  },
  {
    version: "0.28",
    date: "February 15, 2023",
    summary: "Updates to existing features and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Email UtiliBot Info",
        description:
          "The Email UtiliBot now shows information about the emails sent after form submission.",
      },
      {
        type: "Feature",
        title: "2. Layer Filter",
        description:
          "The Layer list filter now allows users to select 'Does Not Equal' for fields with options.",
      },
      {
        type: "Bug",
        title: "3. Multi-Email Saving",
        description:
          "Fixed an issue where email fields with multiple email inputs did not save to GIS and UtiliSync fields as expected which caused issues with form pre-population.",
      },
      {
        type: "Bug",
        title: "4. Action Item User Assignments",
        description:
          "Fixed an issue where the Organization User dropdown contained site contact users.",
      },
      {
        type: "Bug",
        title: "5. Task Counter",
        description:
          "Fixed an issue where the task counter on the info panel included 'Cancelled' action items in the count.",
      },
      {
        type: "Bug",
        title: "6. Site Page Form Viewing",
        description:
          "Fixed an issue where the form dialog was not opening on the site page.",
      },
      {
        type: "Bug",
        title: "7. Existing Tasks Dialog",
        description:
          "Fixed an issue where the existing tasks dialog did not appear upon starting a form when a task with the same form was active.",
      },
    ],
  },
  {
    version: "0.27",
    date: "February 10, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. iPad PDF Viewing",
        description:
          "Fixed an issue where viewing a PDF document on an iPad did not work as expected.",
      },
      {
        type: "Bug",
        title: "2. Uneditable Forms",
        description:
          "Fixed an issue where forms were uneditable if opening a draft or opening a finaled form submission from the log.",
      },
      {
        type: "Bug",
        title: "3. Action Item Email Notifications",
        description:
          "Fixed an issue where action item notifications were not getting sent after form completion.",
      },
    ],
  },
  {
    version: "0.26",
    date: "February 3, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. File Field Descriptions",
        description:
          "Fixed an issue where file field descriptions on forms did not save.",
      },
    ],
  },
  {
    version: "0.25",
    date: "January 31, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Cityworks Link",
        description:
          "Updated Cityworks links on forms to work with servers, regardless of capitalization.",
      },
      {
        type: "Bug",
        title: "2. Close Task Bug",
        description:
          "Fixed a bug where opening a form from a task, then saving the form as draft, then reopening the form resulted in the task not being closed when the form was submitted final.",
      },
      {
        type: "Bug",
        title: "3. Close Task Bug",
        description:
          "Fixed a bug where site links didn't generate when there were special characters in the site name.",
      },
    ],
  },
  {
    version: "0.24",
    date: "January 30, 2023",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Map Service Symbols",
        description: "Map service symbols are now shown in the Layers widget.",
      },
    ],
  },
  {
    version: "0.23",
    date: "January 24, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Blank PDF Generation",
        description:
          "Fixed an issue where a blank PDF was generated after form completion in some cases.",
      },
    ],
  },
  {
    version: "0.22",
    date: "January 21, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. In-App Notifications",
        description:
          "Fixed an issue where the reference field was not getting set as expected for notifications on UtiliSync layers.",
      },
    ],
  },
  {
    version: "0.21",
    date: "January 18, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Shared Sites",
        description:
          "Fixed an issue where only the first 10 sites were available to site contact users on their Sites page.",
      },
      {
        type: "Bug",
        title: "2. New Layer Display",
        description:
          "Fixed an issue where new layers created did not display correctly.",
      },
      {
        type: "Bug",
        title: "3. Form Editing Issue",
        description:
          "Fixed an issue where some fields were able to be updated while not editing the form.",
      },
    ],
  },
  {
    version: "0.20",
    date: "January 13, 2023",
    summary: "Updated features and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Added New App URL",
        description:
          "This app can now be accessed at <a href='https://docs.utilisync.app' target='_blank'>docs.utilisync.app</a>. Please install the app on that page and delete <a href='https://fr.utilisync.app' target='_blank'>fr.utilisync.app</a>. The <a href='https://fr.utilisync.app' target='_blank'>fr.utilisync.app</a> will eventually be removed.",
      },
      {
        type: "Bug",
        title: "2. Table Field Issue",
        description:
          "Fixed an issue where the user could delete a row on a table field while not editing the form.",
      },
      {
        type: "Bug",
        title: "3. Action Item Field Issue",
        description:
          "Fixed an issue where the user could delete an action item while not editing the form.",
      },
      {
        type: "Bug",
        title: "4. Public Link Creation",
        description:
          "Fixed an issue where public site links could be created before adding the site name.",
      },
      {
        type: "Bug",
        title: "5. Error Loading Site Page",
        description:
          "Fixed an issue where the site page would not load under certain circumstances.",
      },
      {
        type: "Bug",
        title: "6. Cityworks Links",
        description:
          "Fixed an issue where the Cityworks links on forms were not loading the organization's Cityworks pages as expected.",
      },
    ],
  },
  {
    version: "0.19",
    date: "January 2, 2023",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Site Contact Emails",
        description:
          "Fixed an issue where site contacts accessing the sites page could not input email in all caps.",
      },
      {
        type: "Bug",
        title: "2. Action Items",
        description:
          "Fixed multiple issues with action item data not displaying correctly.",
      },
    ],
  },
  {
    version: "0.18",
    date: "December 28, 2022",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Cityworks Related Activity Link",
        description:
          "Fixed an issue with the Cityworks related activity link where the user's Cityworks data was not used as expected.",
      },
    ],
  },
  {
    version: "0.17",
    date: "December 22, 2022",
    summary: "Updates to existing features and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Public Site Page Data",
        description:
          "In-app notifications now loads the correct information when clicked.",
      },
      {
        type: "Bug",
        title: "2. PDF Markup Fix",
        description:
          "Fixed an issue with viewing PDFs after marking them up where the correct page didn't display.",
      },
      {
        type: "Bug",
        title: "3. Public Site Page Data",
        description:
          "Fixed an issue where the public site page didn't show data as expected on secured feature service layers.",
      },
      {
        type: "Bug",
        title: "4. Action Item Fixes",
        description:
          "Fixed multiple issues with action item data not displaying correctly.",
      },
    ],
  },
  {
    version: "0.16",
    date: "December 19, 2022",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Action Item Photos",
        description:
          "Fixed an issue where photos added to an action item were lost if the user closed out of the dialog before the photo could load.",
      },
      {
        type: "Bug",
        title: "2. GIS Field Update Error",
        description:
          "Fixed an issue where GIS fields could not be manually updated on some layers.",
      },
    ],
  },
  {
    version: "0.15",
    date: "December 15, 2022",
    summary:
      "Additional features, updates to additional features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Associated Cityworks Activity",
        description:
          "Forms with Cityworks integrations now have quick links to the associated Cityworks activity created.",
      },
      {
        type: "Feature",
        title: "2. Error Checking For GIS Field Updates",
        description:
          "If GIS fields fail to update, an error is now displayed alerting the user.",
      },
      {
        type: "Bug",
        title: "3. Table Paragraph Field",
        description:
          "Fixed an issue where the paragraph field in a table didn't display correctly.",
      },
      {
        type: "Bug",
        title: "4. Moving Sites",
        description:
          "Fixed an issue where the confirmation to move a site disappeared while moving a site.",
      },
    ],
  },
  {
    version: "0.14",
    date: "December 12, 2022",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Optimized Opening Info Panel and Starting Forms",
        description: "Forms and info panel now load faster.",
      },
      {
        type: "Feature",
        title: "2. Offline Forms",
        description:
          "Forms can now be completed while offline and then submitted once connected to the internet again.",
      },
      {
        type: "Feature",
        title: "3. Correct Image Orientation",
        description:
          "Images now keep their original orientation when being uploaded into photo fields in the organization end of the app.",
      },
      {
        type: "Feature",
        title: "4. GPS Widget",
        description:
          "Updated existing GPS tracking widget to first show the current location of user and provide an option to turn GPS tracking mode on.",
      },
      {
        type: "Bug",
        title: "5. Finaled Forms",
        description:
          "Fixed an issue where some UtiliSync fields did not save its data as expected.",
      },
      {
        type: "Bug",
        title: "6. Loading Map",
        description: "Fixed an issue that occurred when loading the map page.",
      },
      {
        type: "Bug",
        title: "7. Calculation Field Issue",
        description:
          "Fixed an issue where calculation fields did not accept input from single select fields with number values.",
      },
    ],
  },
  {
    version: "0.13",
    date: "December 2, 2022",
    summary: "Additional feature and bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Optimize App Speed",
        description:
          "Additional app speed optimizations related to opening the info panel and forms.",
      },
      {
        type: "Feature",
        title: "2. Updated Button Order on Info Panel",
        description:
          "Moved 'Site Contacts' above 'Create Public Link' in the info panel to better align with user workflow.",
      },
    ],
  },
  {
    version: "0.12",
    date: "December 1, 2022",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Signature Form Field",
        description:
          "Fixed an issue with the signature form field that was introduced with version 0.11.",
      },
    ],
  },
  {
    version: "0.11",
    date: "November 30, 2022",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Email Field Validation",
        description:
          "Validate email on forms (only allow emails in the correct format).",
      },
      {
        type: "Feature",
        title: "2. Download Data Faster",
        description:
          "Optimized downloading of app data to decrease wait times while loading the app and forms.",
      },
      {
        type: "Feature",
        title: "3. Associated Cityworks Activity",
        description:
          "Forms that are Cityworks enabled will now show the associate work oder or inspection associated with the form completed.",
      },
      {
        type: "Bug",
        title: "4. Finaled Forms",
        description:
          "Fixed an issue where finaled forms that were set to not allow editing appeared to be editable.",
      },
      {
        type: "Bug",
        title: "5. GIS Data Defaulting",
        description:
          "Fixed an issue where GIS fields for feature services were not defaulting in on forms.",
      },
      {
        type: "Bug",
        title: "6. Image Rotation",
        description:
          "Fixed an issue where portrait images loaded in as landscape orientation.",
      },
    ],
  },
  {
    version: "0.10",
    date: "November 23, 2022",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. IDs Added To Features On UtiliSync Layers",
        description:
          "Added Feature ID and Global ID fields to features on UtiliSync layers for added information for users.",
      },
      {
        type: "Feature",
        title: "2. Auto Save Before Adding Photos",
        description:
          "Forms now save before adding photos to safeguard progress on form.",
      },
      {
        type: "Bug",
        title: "3. Save Draft Not Working",
        description:
          "Fixed an issue where the 'Save Draft' button did not save the form as a draft.",
      },
      {
        type: "Bug",
        title: "4. Error Using Default Values From GIS",
        description:
          "Fixed and issue where default values from GIS weren't populating on the form correctly if the value contained a comma.",
      },
    ],
  },
  {
    version: "0.09",
    date: "November 21, 2022",
    summary: "Updates to existing features and bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. UtiliSync Layer Data Display Issue",
        description:
          "Fixed an issue where the attributes on UtiliSync layers weren't displaying correctly.",
      },
    ],
  },
  {
    version: "0.08",
    date: "November 10, 2022",
    summary: "Updates to existing features and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Moved More Options Menu In Form",
        description:
          "The more options menu in form has been moved into the form top-bar to stop accidental form submissions.",
      },
      {
        type: "Feature",
        title: "2. Table Field Added Functionality",
        description:
          "In table fields that have set default values, admins can now disable the ability to add and delete rows for that field.",
      },
      {
        type: "Feature",
        title: "3. Expanded Info Dialog Visual Updates",
        description:
          "Updates to the expanded info dialog to display the data more efficiently on screen.",
      },
      {
        type: "Bug",
        title: "4. Cityworks Activities Issue",
        description:
          "Fixed an issue where Cityworks activities weren't being updated when there was a single quote in the value that was attempting to be saved to Cityworks. Now, single quotes are removed from values before attempting to save them to Cityworks.",
      },
      {
        type: "Bug",
        title: "5. Action Item Issues",
        description:
          "Fixed an issue where existing action items did not load into action item fields in forms. Fixed an issue where some action items were showing multiple times in the Task panel.",
      },
      {
        type: "Bug",
        title: "6. Esri Main Table Issue",
        description:
          "Fixed an issue where update Esri main table UtiliBot didn't work for some ArcGIS Enterprise servers.",
      },
      {
        type: "Bug",
        title: "7. Symbol Display Issue On Map",
        description:
          "Fixed an issue where symbols didn't display correctly on the map when using UtiliSync field options.",
      },
      {
        type: "Bug",
        title: "8. Site Contact Page Sign-In Issue",
        description:
          "Updated site contact page to show the sign-in dialog when the link for the user has expired.",
      },
    ],
  },
  {
    version: "0.07",
    date: "November 3, 2022",
    summary: "Updates to an existing feature and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Cityworks UtiliBot Error Reporting",
        description:
          "There is now a more detailed message if the Cityworks UtiliBot fails, including the failed request. Previously, the error message didn't provide helpful information.",
      },
      {
        type: "Bug",
        title: "2. Opening Photos on Edit Form",
        description:
          "When clicking on a photo while editing a form now opens the photo in markup mode. Previously, the photo was opening in view mode.",
      },
      {
        type: "Bug",
        title: "3. Viewing Photos on Finaled Form",
        description:
          "Clicking on a photo on a finaled form now opens the photo for viewing. Previously, it was opening in markup mode.",
      },
    ],
  },
  {
    version: "0.06",
    date: "November 1, 2022",
    summary: "Updates to an existing feature and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Updated Shared Site Page Data",
        description:
          "Site Contacts can now see the tasks that are assigned to other site contacts on the site.",
      },
      {
        type: "Bug",
        title: "2. Layers View on Map Issue",
        description:
          "Fixed an issue where clicking on a row in the Layers view on the Maps page did not take the user to the feature on the map.",
      },
      {
        type: "Bug",
        title: "3. Unable to Move Sites",
        description:
          "Fixed an issue where users where unable to move features on the map.",
      },
      {
        type: "Bug",
        title: "4. Adding Features to Map",
        description:
          "Fixed an issue where users were unable to add features to a map.",
      },
      {
        type: "Bug",
        title: "5. Site Contacts Starting Forms",
        description:
          "Fixed an issue where site contacts were unable to start a form on host's secured feature service layers.",
      },
    ],
  },
  {
    version: "0.05",
    date: "October 21, 2022",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Cityworks-Enabled Forms",
        description:
          "Fixed an issue where users were able to start a Cityworks-enabled form without being signed in to Cityworks.",
      },
      {
        type: "Bug",
        title: "2. Site Contact File Upload",
        description:
          "Fixed an issue where, in some cases, site contact users were unable to upload files to the shared site.",
      },
      {
        type: "Bug",
        title: "3. Task Bugs",
        description: "Fixed multiple issues with tasks.",
      },
    ],
  },
  {
    version: "0.04",
    date: "October 13, 2022",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Cityworks Single Sign On Issue",
        description:
          "Fixed an issue where users were not able to sign in to Cityworks using ArcGIS SSO.",
      },
    ],
  },
  {
    version: "0.03",
    date: "October 13, 2022",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Issue with Photos",
        description:
          "Fixed an issue where users were unable to add photos in completion of a form to sites on a Feature Service layer without a correctly formatted GlobalID field.",
      },
      {
        type: "Bug",
        title: "2. Error Uploading Files",
        description:
          "Fixed an issue where files and photos were unable to be uploaded to a site.",
      },
      {
        type: "Bug",
        title: "3. Photo Thumbnails",
        description:
          "Fixed an issue where after marking up an image, the thumbnail of the image shrunk.",
      },
    ],
  },
  {
    version: "0.02",
    date: "October 6, 2022",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. GIS Field Domain",
        description:
          "Fixed an issue for GIS fields with domains where the value, not the label, was showing. Now the domain label shows in the info panel.",
      },
      {
        type: "Bug",
        title: "2. Assigned To Filter Issue",
        description:
          "Fixed an issue where the Assigned To filter only worked for Scheduled Forms assigned to your own user.",
      },
      {
        type: "Bug",
        title: "3. Scheduled Forms View",
        description:
          "Fixed an issue where the Scheduled Forms view only received newly created Scheduled Forms after the app was reloaded.",
      },
    ],
  },
  {
    version: "0.01",
    date: "October 5, 2022",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Form Submitted Pop-Up",
        description:
          "Added a pop-up message that appears upon completion of a form.",
      },
      {
        type: "Feature",
        title: "2. Layers Dropdown",
        description:
          "Users can now search layers by typing in the layers dropdown on the list view.",
      },
      {
        type: "Bug",
        title: "3. Single Select as Image",
        description:
          "Fixed an issue where single select fields set with images did not display on the generated PDF.",
      },
      {
        type: "Bug",
        title: "4. Missing Data For GIS Layers",
        description:
          "Fixed an issue where in the list view on the maps page, feature data did not load for table.",
      },
    ],
  },
];
