var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.sharedSiteTokenLoaded
        ? _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm.currentUser
                ? _c(
                    "v-list-item",
                    { staticClass: "px-0", attrs: { id: "userDropdown" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", "min-width": "244px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "#3F51B5" } },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-list-item-content", [
                                          _c(
                                            "section",
                                            {
                                              staticClass:
                                                "d-flex justify-end align-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mx-0 px-0",
                                                  class: {
                                                    "flex-grow-0":
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp,
                                                    "flex-grow-1":
                                                      _vm.$vuetify.breakpoint
                                                        .xsOnly,
                                                    truncate:
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                  },
                                                  staticStyle: {
                                                    color: "#3f51b5"
                                                  },
                                                  style: {
                                                    width: _vm.$vuetify
                                                      .breakpoint.smAndUp
                                                      ? "180px"
                                                      : undefined
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "section",
                                                    {
                                                      staticClass:
                                                        "font-weight-medium",
                                                      class: {
                                                        truncate:
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp
                                                      },
                                                      staticStyle: {
                                                        "font-size": "14px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.currentUser
                                                              .email
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "px-0 mx-0",
                                                  attrs: {
                                                    icon: "",
                                                    color: "#673AB7"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#3F51B5"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.mdiChevronDown
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2377984845
                          )
                        },
                        [
                          _c(
                            "v-list",
                            { staticClass: "py-0 px-0" },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "py-1 px-0",
                                  attrs: { id: "siteContactSignOut" },
                                  on: { click: _vm.signOut }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "min-button",
                                      attrs: {
                                        id: "sign-out-button",
                                        text: "",
                                        color: "#3f51b5"
                                      }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v(_vm._s(_vm.mdiLogoutVariant))
                                      ]),
                                      _vm._v(" Sign Out ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-list-item",
                {
                  staticClass: "py-1 my-0",
                  attrs: {
                    "active-class": "text--lighten-5",
                    to: "/sites",
                    id: "sitesList"
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                        _vm._v(_vm._s(_vm.mdiFormatListCheckbox))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "font-weight-medium",
                      staticStyle: { color: "#3f51b5" }
                    },
                    [_vm._v(" Sites ")]
                  )
                ],
                1
              ),
              _vm.publicSite && _vm.publicSite.url_id
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "py-1 my-0",
                      attrs: {
                        to: "/" + _vm.publicSite.url_id,
                        "active-class": "text--lighten-5",
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                            _vm._v(_vm._s(_vm.mdiLink))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        {
                          staticClass: "font-weight-medium",
                          staticStyle: { color: "#3f51b5" },
                          attrs: { id: "publicSite" }
                        },
                        [_vm._v(" Public Site ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-list-item",
                {
                  staticClass: "py-1 my-0",
                  attrs: {
                    href:
                      "https://utilisync.helpscoutdocs.com/collection/240-site-contacts#",
                    target: "_blank"
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                        _vm._v(_vm._s(_vm.mdiBookOpen))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "font-weight-medium",
                      staticStyle: { color: "#3f51b5" },
                      attrs: { id: "knowledgeBase" }
                    },
                    [_vm._v(" Knowledge Base ")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "py-1 my-0", on: { click: _vm.showChat } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                        _vm._v(_vm._s(_vm.mdiHelpCircle))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "font-weight-medium",
                      staticStyle: { color: "#3f51b5" },
                      attrs: { id: "support" }
                    },
                    [_vm._v(" Support ")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "py-1 my-0", on: { click: _vm.reload } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                        _vm._v(_vm._s(_vm.mdiReload))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "font-weight-medium",
                      staticStyle: { color: "#3f51b5" },
                      attrs: { id: "reload" }
                    },
                    [_vm._v(" Reload ")]
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-item",
                { staticClass: "py-1 my-0", attrs: { to: "/versionLog" } },
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "font-weight-medium",
                      staticStyle: { "font-size": "14px", color: "#3f51b5" }
                    },
                    [_vm._v(" Version " + _vm._s(_vm.versionNumber) + " ")]
                  )
                ],
                1
              ),
              _c("v-divider")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }