import Dexie from "dexie";

const db = new Dexie("utilisync");

db.version(1).stores({
  mapServices: "map_service_id, service_name, service_type",
  users:
    "user_id, user_group_id, username, email, f_name, l_name, is_active, is_gis_admin, is_power_user, is_view_only_user, locate_requests_user, time_zone",
  formDefinitions:
    "form_definition_id, form_object_id, map_service_id, service_name",
  maps: "map_id, name, center_x_coord, center_y_coord, user_group_id, wkid, zoom_level",
  userDataFields: "user_data_field_id, name, user_group_id",
  userDataValues: "user_data_value_id, user_id, value",
  gisDataFieldOptions:
    "gis_data_field_option_id, gis_data_field_id, value, label, last_updated",
  gisDataFields:
    "gis_data_field_id, map_service_id, name, type, editable, visible, visible_when_shared, last_updated",
  gisDataPoints:
    "gis_data_point_id, map_service_id, point_geom, object_id, last_updated",
  gisDataValues:
    "gis_data_value_id, gis_data_field_id, feature_id, value, last_updated",
  markupSymbols:
    "markup_symbol_id, user_group_id, label, symbol, deactivated, last_updated",
  formResults: "form_result_id, payload, computed_form_definition",
  taskTypes: "task_type_id, name, last_updated",
  tasks:
    "task_id, user_group_id, task_type_id, map_service_id, description, created_on, created_by, updated_on, updated_by, assigned_to, assigned_by, assigned_on, assignee_closable, due, status, map_service_task_id, form_definition_id, feature_id, cityworks_activity_id, task_notification_template_id, global_id, feature_attributes, last_updated",
});
export { db };
