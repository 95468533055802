var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", persistent: "" },
      model: {
        value: _vm.showCaptureShowWidget,
        callback: function($$v) {
          _vm.showCaptureShowWidget = $$v
        },
        expression: "showCaptureShowWidget"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("v-toolbar-title", [_c("div", [_vm._v("View Capture")])]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.tryClose()
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _vm.uploaded
            ? _c(
                "div",
                [
                  _c("v-card-text", [
                    _c("div", [
                      _vm._v(
                        " The capture link has been created. Copy the capture link below and send to UtiliSync Support. "
                      )
                    ])
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "#00A9F4",
                                text: "",
                                to: "" + _vm.videoLink,
                                target: "_blank"
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.mdiOpenInNew))
                              ]),
                              _vm._v(" View")
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: { color: "#3F51B5", dark: "" },
                              on: {
                                click: function($event) {
                                  return _vm.copyCaptureLink()
                                }
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.mdiContentCopy))
                              ]),
                              _vm._v("Copy")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("v-card-text", { staticClass: "pa-3" }, [
                    _c("div", [
                      _vm._v(
                        " Your video recording has been captured. Upload to create a capture link. "
                      )
                    ]),
                    _c("div", { staticClass: "d-flex justify-center" }, [
                      _c("video", {
                        ref: "videoElement",
                        staticClass: "videoVisual",
                        attrs: { controls: "", width: "480", height: "360" }
                      })
                    ]),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm.isUploading
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c("v-progress-linear", {
                                attrs: {
                                  color: "#00A9F4",
                                  height: "25",
                                  rounded: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var value = ref.value
                                        return [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(Math.ceil(value)) + "%"
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3002676263
                                ),
                                model: {
                                  value: _vm.uploadPercentage,
                                  callback: function($$v) {
                                    _vm.uploadPercentage = $$v
                                  },
                                  expression: "uploadPercentage"
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", { staticStyle: { height: "25px" } })
                    ])
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end px-5 pt-0 pb-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#00A9F4", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.downloadVideo()
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.mdiDownload))
                          ]),
                          _vm._v("Download ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "#3F51B5",
                            disabled: _vm.isUploading
                          },
                          on: {
                            click: function($event) {
                              return _vm.uploadCapture()
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.mdiUpload))
                          ]),
                          _vm._v("Upload ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000 },
          model: {
            value: _vm.showCopiedLinkSnack,
            callback: function($$v) {
              _vm.showCopiedLinkSnack = $$v
            },
            expression: "showCopiedLinkSnack"
          }
        },
        [_vm._v(" Copied capture link! ")]
      ),
      _vm.isUploading
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "450px" },
              model: {
                value: _vm.showWarningDialog,
                callback: function($$v) {
                  _vm.showWarningDialog = $$v
                },
                expression: "showWarningDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "elevation-0",
                      attrs: { dark: "", width: "100%", color: "#3F51B5" }
                    },
                    [
                      _c("v-toolbar-title", [
                        _c("div", [_vm._v("Currently Uploading")])
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.showWarningDialog = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "pt-2" }, [
                    _vm._v(
                      " Your capture is currently uploading. If you close now, your capture may be lost."
                    )
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end px-5 py-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#00A9F4", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showWarningDialog = false
                            }
                          }
                        },
                        [_vm._v(" Return To Upload ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#3F51B5", dark: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("capture-show-dialog-close")
                            }
                          }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }