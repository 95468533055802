var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.showUserNotificationsDialog,
        callback: function($$v) {
          _vm.showUserNotificationsDialog = $$v
        },
        expression: "showUserNotificationsDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "overflow-y-auto" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "#3F51B5" }
            },
            [
              _c("v-toolbar-title", [_vm._v("Notifications")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("user-notifications-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "py-3" }, [
            _c(
              "div",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "text-caption font-weight-medium",
                        staticStyle: { color: "black" },
                        attrs: { cols: "6" }
                      },
                      [_vm._v(" Notification ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-caption font-weight-medium",
                        staticStyle: { color: "black", "padding-left": "8px" },
                        attrs: { cols: "2" }
                      },
                      [_vm._v(" Email ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-caption font-weight-medium",
                        staticStyle: { color: "black", "padding-left": "10px" },
                        attrs: { cols: "2" }
                      },
                      [_vm._v(" Text ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-caption font-weight-medium",
                        staticStyle: { color: "black", "padding-left": "6px" },
                        attrs: { cols: "2" }
                      },
                      [_vm._v(" In-App ")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "text-subtitle-1 my-0 py-0",
                        staticStyle: { color: "#444444" },
                        attrs: { cols: "10" }
                      },
                      [_vm._v(" Utilibot Failures ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "my-0 py-0", attrs: { cols: "2" } },
                      [
                        _c("v-checkbox", {
                          staticClass: "my-0 py-0",
                          attrs: { "input-value": "true", disabled: true }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._l(
                  _vm.newNotificationSettings.filter(function(setting) {
                    return setting.notification_type !== "daily_task_summary"
                  }),
                  function(notificationSetting) {
                    return _c(
                      "v-row",
                      {
                        key: notificationSetting.id,
                        staticClass: "py-1 my-1",
                        staticStyle: { "max-height": "24px" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-subtitle-1 my-0 py-0",
                            staticStyle: { color: "#444444" },
                            attrs: { cols: "6" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getFormattedName(
                                    notificationSetting.notification_type
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "my-0 py-0", attrs: { cols: "2" } },
                          [
                            _c("v-checkbox", {
                              staticClass: "my-0 py-0",
                              model: {
                                value: notificationSetting.email,
                                callback: function($$v) {
                                  _vm.$set(notificationSetting, "email", $$v)
                                },
                                expression: "notificationSetting.email"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.userInformation.sms_notification === null
                          ? _c(
                              "v-col",
                              {
                                staticClass: "my-0 py-0",
                                attrs: { cols: "2" }
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "my-0 py-0",
                                                    attrs: {
                                                      disabled: !_vm
                                                        .userInformation
                                                        .sms_notification
                                                    },
                                                    model: {
                                                      value:
                                                        notificationSetting.sms,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          notificationSetting,
                                                          "sms",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "notificationSetting.sms"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Add a phone number to enable")
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _c(
                              "v-col",
                              {
                                staticClass: "my-0 py-0",
                                attrs: { cols: "2" }
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "my-0 py-0",
                                  model: {
                                    value: notificationSetting.sms,
                                    callback: function($$v) {
                                      _vm.$set(notificationSetting, "sms", $$v)
                                    },
                                    expression: "notificationSetting.sms"
                                  }
                                })
                              ],
                              1
                            ),
                        _c(
                          "v-col",
                          { staticClass: "my-0 py-0", attrs: { cols: "2" } },
                          [
                            _c("v-checkbox", {
                              staticClass: "my-0 py-0",
                              model: {
                                value: notificationSetting.in_app,
                                callback: function($$v) {
                                  _vm.$set(notificationSetting, "in_app", $$v)
                                },
                                expression: "notificationSetting.in_app"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }
                )
              ],
              2
            )
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex mx-1 my-1 px-2 py-2",
              class: { mobileScreen: _vm.$vuetify.breakpoint.xsOnly }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "#3F51B5", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.saveNotifications()
                    }
                  }
                },
                [_vm._v(" Save Notifications ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }