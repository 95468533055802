import { db } from "./utilisync-db";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      downloadingFormDefinitions: false,
      downloadingMapServices: false,
      downloadingUsers: false,
      downloadingTaskTypes: false,
      downloadingTasks: false,
      downloadingMaps: false,
      downloadingGisDataValues: false,
      downloadingGisDataPoints: false,
      downloadingGisDataFields: false,
      downloadingGisDataFieldOptions: false,
    };
  },
  methods: {
    async bulkDownloadData() {
      await this.bulkDownloadFormDefinitions();
      await this.bulkDownloadMaps();
      await this.bulkDownloadMapServices();
      await this.bulkDownloadUsers();
      await this.bulkDownloadTaskTypes();
      await this.bulkDownloadTasks();
      await this.bulkDownloadGisDataPoints();
      await this.bulkDownloadGisDataFields();
      await this.bulkDownloadGisDataValues();
      await this.bulkDownloadGisDataFieldOptions();
    },
    async bulkDownloadFormDefinitions() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingFormDefinitions = true;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/form_definitions`);
      await db.formDefinitions.bulkPut(results);
      this.downloadingFormDefinitions = false;
    },
    async bulkDownloadMapServices() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingMapServices = true;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/map_services`);
      await db.mapServices.bulkPut(results);
      this.downloadingMapServices = false;
    },
    async bulkDownloadUsers() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingUsers = true;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/users`);
      await db.users.bulkPut(results);
      this.downloadingUsers = false;
    },
    async bulkDownloadTaskTypes() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingTaskTypes = true;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/task_types`);
      await db.taskTypes.bulkPut(results);
      this.downloadingTaskTypes = false;
    },
    async bulkDownloadTasks() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingTasks = true;
      const {
        data: { results: openTasks },
      } = await axiosWithRegularAuth.get(`${APIURL}/tasks/user_group_open`);
      await db.tasks.bulkPut(openTasks);
      this.downloadingTasks = false;
    },
    async bulkDownloadMaps() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingMaps = true;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/maps/withoutlocateservice`);
      await db.maps.bulkPut(results);
      this.downloadingMaps = true;
    },
    async bulkDownloadGisDataFields() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingGisDataFields = false;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/gis_data_fields_by_user_group`
      );
      await db.gisDataFields.bulkPut(results);
      this.downloadingGisDataFields = true;
    },
    async bulkDownloadGisDataValues() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingGisDataValues = false;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/gis_data_values/all`);
      await db.gisDataValues.bulkPut(results);
      this.downloadingGisDataValues = true;
    },
    async bulkDownloadGisDataPoints() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingGisDataPoints = false;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/gis_data_points`);
      await db.gisDataPoints.bulkPut(results);
      this.downloadingGisDataPoints = true;
    },
    async bulkDownloadGisDataFieldOptions() {
      if (!navigator.onLine) {
        return;
      }
      this.downloadingGisDataFieldOptions = false;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/gis_data_field_options/download`
      );
      await db.gisDataFieldOptions.bulkPut(results);
      this.downloadingGisDataFieldOptions = true;
    },
  },
};
