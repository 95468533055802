var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm.currentUser
            ? _c(
                "v-list-item",
                { staticClass: "px-0" },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "", "min-width": "244px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { color: "#3F51B5" } },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-list-item-content", [
                                      _c(
                                        "section",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between align-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mx-0 px-0",
                                              class: {
                                                "flex-grow-0":
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp,
                                                "flex-grow-1":
                                                  _vm.$vuetify.breakpoint
                                                    .xsOnly,
                                                truncate:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                              },
                                              style: {
                                                width: _vm.$vuetify.breakpoint
                                                  .smAndUp
                                                  ? "150px"
                                                  : undefined
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "caption menu-text font-weight-medium indigo--text",
                                                  class: {
                                                    truncate:
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.currentUser
                                                          .organization &&
                                                          _vm.currentUser
                                                            .organization.name
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-medium indigo--text",
                                                  class: {
                                                    truncate:
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                  },
                                                  staticStyle: {
                                                    "font-size": "16px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.userInformation
                                                          .f_name
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.userInformation
                                                          .l_name
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "px-0 mx-0",
                                              attrs: {
                                                icon: "",
                                                color: "#673AB7"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "#3F51B5" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.mdiChevronDown)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1990281084
                      )
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "py-0 px-0" },
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "py-0 px-0",
                              on: {
                                click: function($event) {
                                  _vm.showUserInformationDialog = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "min-button",
                                  attrs: { text: "", color: "#3f51b5" }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(_vm.mdiAccount))
                                  ]),
                                  _vm._v(" User Information ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "py-0 px-0",
                              on: {
                                click: function($event) {
                                  _vm.showUserNotificationsDialog = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "min-button",
                                  attrs: { text: "", color: "#3f51b5" }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(_vm.mdiBell))
                                  ]),
                                  _vm._v(" Notifications ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          !_vm.hasValidCityworksToken
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "py-0 px-0",
                                  on: {
                                    click: function($event) {
                                      _vm.showCityworksSignInDialog = true
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "min-button",
                                      attrs: { text: "", color: "#3f51b5" }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v(_vm._s(_vm.mdiLoginVariant))
                                      ]),
                                      _vm._v(" Sign In - Cityworks ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-list-item",
                                {
                                  staticClass: "py-0 px-0",
                                  on: { click: _vm.signOutOfCityworks }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "min-button",
                                      attrs: { text: "", color: "#3f51b5" }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v(_vm._s(_vm.mdiLogoutVariant))
                                      ]),
                                      _vm._v(" Sign Out - Cityworks ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "py-0 px-0",
                              on: { click: _vm.showWarningDialogOrSignOut }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "min-button",
                                  attrs: {
                                    id: "sign-out-button",
                                    text: "",
                                    color: "#3f51b5"
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(_vm.mdiLogoutVariant))
                                  ]),
                                  _vm._v(" Sign Out ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/map", "active-class": "text--lighten-5" }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiMap))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Maps ")]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              staticClass: "py-1 my-0 align-center",
              attrs: {
                to: {
                  path: "/upload"
                },
                id: "upload-tickets"
              }
            },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "align-center" },
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiUpload))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Upload ")]
              ),
              _vm.numUnsubmittedTickets > 0
                ? _c(
                    "v-list-item-avatar",
                    { staticClass: "my-0 my-0" },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { color: "orange", size: "25" } },
                        [
                          _c(
                            "span",
                            { staticClass: "menu-text headline pa-2 caption" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.numUnsubmittedTickets) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: {
                to: {
                  path: "/list"
                },
                "active-class": "text--lighten-5"
              }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiFormatListCheckbox))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Layers ")]
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: {
                to: {
                  path: "/scheduledForms"
                },
                "active-class": "text--lighten-5"
              }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiCalendarCheck))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Scheduled Forms ")]
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: {
                to: {
                  path: "/actionItems"
                },
                "active-class": "text--lighten-5"
              }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiCheckboxMarkedOutline))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Action Items ")]
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            { staticClass: "py-0 my-0", attrs: { to: "/formSubmission" } },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiFileMultipleOutline))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Form Submissions ")]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              on: {
                click: function($event) {
                  return _vm.$emit("open-capture-upload-dialog")
                }
              }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiCameraIris))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Capture Screen ")]
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: {
                href:
                  "https://utilisync.helpscoutdocs.com/collection/1-field-reports",
                target: "_blank"
              }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiBookOpen))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Knowledge Base ")]
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            { staticClass: "py-0 my-0", on: { click: _vm.showChat } },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(_vm._s(_vm.mdiHelpCircle))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [_vm._v(" Support ")]
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0 d-flex align-center",
              attrs: { "two-line": Boolean(_vm.lastFullDownloadCompleted) },
              on: { click: _vm.reload }
            },
            [
              _vm.lastFullDownloadCompleted
                ? _c(
                    "div",
                    { staticClass: "my-0 py-0 pr-8 d-flex align-center" },
                    [
                      _vm.reloading
                        ? _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: 20,
                              color: "#3F51B5"
                            }
                          })
                        : _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                            _vm._v(_vm._s(_vm.mdiReload))
                          ])
                    ],
                    1
                  )
                : _c(
                    "v-list-item-icon",
                    [
                      _vm.reloading
                        ? _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: 20,
                              color: "#3F51B5"
                            }
                          })
                        : _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                            _vm._v(_vm._s(_vm.mdiReload))
                          ])
                    ],
                    1
                  ),
              _c(
                "v-list-item-content",
                { staticClass: "font-weight-medium indigo--text" },
                [
                  _c("div", [_vm._v("Sync and Reload")]),
                  _vm.lastFullDownloadCompleted
                    ? _c("div", { staticClass: "caption" }, [
                        _vm._v(
                          " Last Synced " +
                            _vm._s(
                              _vm._f("formatVariableLocalDate")(
                                _vm.lastFullDownloadCompleted,
                                "hh:mm A"
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm.currentUser && _vm.currentUser.is_gis_admin
            ? _c(
                "v-list-item",
                {
                  staticClass: "py-0 my-0",
                  on: {
                    click: function($event) {
                      return _vm.openUserAdmin()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                        _vm._v(_vm._s(_vm.mdiOpenInNew))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-medium indigo--text" },
                    [_vm._v(" Admin App ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _c(
            "v-list-item",
            { staticClass: "py-0 my-0", attrs: { to: "/versionLog" } },
            [
              _c(
                "v-list-item-content",
                {
                  staticClass: "font-weight-medium indigo--text",
                  staticStyle: { "font-size": "14px", color: "#3f51b5" }
                },
                [_vm._v(" Version " + _vm._s(_vm.versionNumber) + " ")]
              )
            ],
            1
          ),
          _c("v-divider")
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.showSignOutDialog,
            callback: function($$v) {
              _vm.showSignOutDialog = $$v
            },
            expression: "showSignOutDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _vm._v(
                              " There are tickets saved on this device that have not been submitted. If you proceed, the forms will be permanently deleted. Do you wish to continue? "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showSignOutDialog = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.signOut }
                    },
                    [_vm._v(" Sign Out and Delete Forms from device ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showCityworksSignInDialog
        ? _c("CityworksSignInDialog", {
            attrs: { showCityworksSignInDialog: _vm.showCityworksSignInDialog },
            on: {
              "cityworks-sign-in-dialog-close": function($event) {
                _vm.showCityworksSignInDialog = false
              },
              "cityworks-sign-in-dialog-succeed": function($event) {
                _vm.showCityworksSignInDialog = false
                _vm.updateCityworksToken()
              }
            }
          })
        : _vm._e(),
      _vm.showUserInformationDialog
        ? _c("UserInformationDialog", {
            attrs: {
              showUserInformationDialog: _vm.showUserInformationDialog,
              userInformation: _vm.userInformation
            },
            on: {
              "user-information-dialog-close": function($event) {
                _vm.showUserInformationDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showUserNotificationsDialog
        ? _c("UserNotificationsDialog", {
            attrs: {
              showUserNotificationsDialog: _vm.showUserNotificationsDialog,
              userNotifications: _vm.userNotifications,
              userInformation: _vm.userInformation
            },
            on: {
              "user-notifications-dialog-close": function($event) {
                _vm.showUserNotificationsDialog = false
              },
              "notifications-saved": _vm.getUserNotifications
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }