<template>
  <v-app>
    <v-navigation-drawer
      :permanent="isMenuOpen && $vuetify.breakpoint.smAndUp"
      :bottom="$vuetify.breakpoint.xsOnly"
      :stateless="$vuetify.breakpoint.smAndUp"
      app
      color="#FAFAFA"
      :value="isMenuOpen"
      v-if="!$route.path.includes('login') && !$route.path.includes('signup')"
    >
      <template v-slot:prepend>
        <v-list-item class="mx-0 px-0">
          <img src="@/assets/UtiliSyncDocsLogo.svg" id="logo" />
        </v-list-item>
      </template>

      <SharedSiteNavDrawerList v-if="showSharedSiteNavDrawerList" />
      <NavDrawerList
        :showUploadTickets="showUploadTickets"
        v-else
        @open-capture-upload-dialog="showCaptureOrUploadDialog = true"
      />
    </v-navigation-drawer>

    <v-snackbar v-model="showOnline" :timeout="5000">
      You're back online.
      <template v-slot:action="{ attrs }" v-if="numUnsubmittedTickets > 0">
        <v-btn text v-bind="attrs" @click="$router.push('/upload')">
          Upload Tickets
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="showOffline" :timeout="5000">
      You're offline. Not to worry! You can still complete forms and submit them
      later.
    </v-snackbar>

    <v-snackbar v-model="showUpdateSnackbar" :timeout="-1">
      <template v-slot:action>
        <div class="d-flex justify-space-between">
          <div class="d-flex align-center">
            <div>UtiliSync was just updated.</div>
            <v-btn
              :href="
                $router.resolve({
                  path: '/versionLog',
                }).href
              "
              text
            >
              View What's New
            </v-btn>
          </div>

          <v-btn @click="showUpdateSnackbar = false" icon>
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-snackbar>

    <AlreadyClearedDialog
      :showAlreadyClearedDialog="showAlreadyClearedDialog"
      :ticketNumbersAlreadySubmitted="ticketNumbersAlreadySubmitted"
      :formResultIdsAlreadySubmitted="formResultIdsAlreadySubmitted"
      @delete-forms="showAlreadyClearedDialog = false"
      @keep-forms="showAlreadyClearedDialog = false"
    />

    <ErrorDialog :showErrorDialog="errorOccurred" />

    <BadRequestErrorDialog
      :showBadRequestErrorDialog="Boolean(badRequestError)"
    />

    <ForbiddenErrorDialog
      :showForbiddenErrorDialog="Boolean(forbiddenError)"
      @cityworks-token-error="showCityworksSignInDialog = true"
    />

    <CityworksErrorDialog :showCityworksErrorDialog="Boolean(cityworksError)" />

    <CityworksSignInDialog
      v-if="showCityworksSignInDialog"
      :showCityworksSignInDialog="showCityworksSignInDialog"
      @cityworks-sign-in-dialog-close="showCityworksSignInDialog = false"
      @cityworks-sign-in-dialog-succeed="
        showCityworksSignInDialog = false;
        updateCityworksToken();
      "
    />

    <CaptureOrUploadDialog
      v-if="showCaptureOrUploadDialog"
      @capture-upload-dialog-close="showCaptureOrUploadDialog = false"
      @open-capture-toolbar="
        showCaptureOrUploadDialog = false;
        showCaptureToolbar = true;
      "
      :showCaptureOrUploadDialog="showCaptureOrUploadDialog"
    />

    <CaptureShowWidget
      v-if="showCaptureShowWidget"
      @capture-show-dialog-close="
        videoURL = undefined;
        showCaptureShowWidget = false;
      "
      @close-capture-toolbar="showCaptureToolbar = false"
      :showCaptureShowWidget="showCaptureShowWidget"
      :videoURL="videoURL"
    />

    <CaptureToolBar
      v-if="showCaptureToolbar"
      @close-capture-toolbar="showCaptureToolbar = false"
      @finished-recording="
        videoURL = $event;
        showCaptureShowWidget = true;
      "
    />
    <slot></slot>
  </v-app>
</template>

<script>
import NavDrawerList from "@/components/app/NavDrawerList.vue";
import { mapGetters, mapMutations } from "vuex";
import checkIfOfflineTicketsSubmittedFinalMixin from "@/mixins/checkIfOfflineTicketsSubmittedFinalMixin";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import AlreadyClearedDialog from "@/components/shared/AlreadyClearedDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";
import { setDefaultOptions } from "esri-loader";
import { mdiClose } from "@mdi/js";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import UNAUTHENTICATED_ROUTES from "@/constants/unauthenticatedRoutes";
import ErrorDialog from "@/components/shared/ErrorDialog";
import BadRequestErrorDialog from "@/components/shared/BadRequestErrorDialog";
import logRocketMixin from "@/mixins/logRocketMixin";
import SharedSiteNavDrawerList from "@/components/app/SharedSiteNavDrawerList";
import ForbiddenErrorDialog from "@/components/shared/ForbiddenErrorDialog";
import moment from "moment";
import CityworksSignInDialog from "@/components/app/CityworksSignInDialog";
import authObjectMixin from "@/mixins/authObjectMixin";
import CaptureToolBar from "@/components/app/capture/CaptureToolBar";
import CaptureShowWidget from "@/components/app/capture/CaptureShow";
import CaptureOrUploadDialog from "@/components/app/capture/CaptureOrUpload";
import CityworksErrorDialog from "@/components/shared/CityworksErrorDialog";

setDefaultOptions({ version: "4.26" });

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Layout",
  components: {
    NavDrawerList,
    AlreadyClearedDialog,
    ErrorDialog,
    BadRequestErrorDialog,
    SharedSiteNavDrawerList,
    ForbiddenErrorDialog,
    CityworksSignInDialog,
    CaptureToolBar,
    CaptureShowWidget,
    CaptureOrUploadDialog,
    CityworksErrorDialog,
  },
  computed: {
    ...mapGetters([
      "isMenuOpen",
      "numUnsubmittedTickets",
      "isUpdateAvailable",
      "errorOccurred",
      "badRequestError",
      "forbiddenError",
      "cityworksError",
    ]),
    showSharedSiteNavDrawerList() {
      return ["sharedSite", "sites"].some((url) => {
        return this.$route.fullPath.includes(url);
      });
    },
  },
  data: () => ({
    showOnline: false,
    showOffline: false,
    downloadTicketTimer: undefined,
    showAlreadyClearedDialog: false,
    ticketNumbersAlreadySubmitted: [],
    formResultIdsAlreadySubmitted: [],
    showUploadTickets: false,
    notificationTimer: undefined,
    showUpdateSnackbar: false,
    mdiClose,
    downloadTimer: undefined,
    showForbiddenErrorDialog: false,
    showCityworksSignInDialog: false,
    showCaptureToolbar: false,
    videoURL: undefined,
    showCaptureShowWidget: false,
    showCaptureOrUploadDialog: false,
  }),
  watch: {
    isOnline: {
      immediate: true,
      async handler(val, oldVal) {
        this.showOffline = false;
        this.showOnline = false;
        if (val) {
          if (typeof oldVal === "boolean") {
            this.showOnline = true;
          }
        } else {
          this.showOffline = true;
        }
      },
    },
    showOnline(val) {
      if (!val) {
        this.showUploadTickets = false;
      }
    },
    isUpdateAvailable(val) {
      this.showUpdateSnackbar = val;
    },
  },

  beforeDestroy() {
    clearInterval(this.notificationTimer);
    clearInterval(this.downloadTimer);
  },
  mixins: [
    checkIfOfflineTicketsSubmittedFinalMixin,
    networkStatusMixin,
    downloadDataMixin,
    logRocketMixin,
    authObjectMixin,
  ],
  methods: {
    async updateCityworksToken() {
      await this.updateAuthObject();
      await this.validateCityworksToken();
    },
    async validateCityworksToken() {
      if (!navigator.onLine) {
        return;
      }

      const { cityworks_token: cityworksToken } = this.auth ?? {};
      if (!cityworksToken) {
        this.hasValidCityworksToken = false;
        return;
      }
      try {
        const {
          data: { error },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/cityworks/validate_token`,
          {
            params: {
              cityworks_token: cityworksToken,
            },
          }
        );
        this.hasValidCityworksToken = !error;
      } catch (error) {
        this.hasValidCityworksToken = false;
      } finally {
        if (!this.hasValidCityworksToken) {
          // eslint-disable-next-line no-unused-vars
          const { cityworks_token, ...newAuth } = this.auth;
          localStorage.setItem("auth", JSON.stringify(newAuth));
        }
      }
    },
    async checkNotifications() {
      if (
        !this.$route.name ||
        UNAUTHENTICATED_ROUTES.includes(this.$route.name) ||
        !navigator.onLine
      ) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/notification_in_app/is_new_notification`
      );
      this.setIsNewNotifications(results);
    },
    ...mapMutations([
      "setNumUnsubmittedTickets",
      "setMenuState",
      "setSelectedMapId",
      "setIsNewNotifications",
    ]),
  },
  async beforeMount() {
    this.initLogRocket();
    await this.checkNotifications();
    const selectedMapId = localStorage.getItem("last-map-opened-id");
    this.setSelectedMapId(selectedMapId);
    this.notificationTimer = setInterval(() => {
      this.checkNotifications();
    }, 30 * 1000);

    this.downloadTimer = setInterval(() => {
      if (
        !this.$route.name ||
        UNAUTHENTICATED_ROUTES.includes(this.$route.name) ||
        !navigator.onLine
      ) {
        return;
      }

      const lastFullDownloadCompleted = localStorage.getItem(
        "last-full-download-completed"
      );
      const fullDownload =
        !lastFullDownloadCompleted ||
        (lastFullDownloadCompleted &&
          moment().diff(moment(lastFullDownloadCompleted), "hours") >= 12);
      this.downloadData(!fullDownload);
    }, 5 * 60 * 1000);
  },
};
</script>

<style scoped>
#logo {
  width: 70%;
  padding-top: 20px;
  margin: 0 auto;
}
</style>

<style>
html,
body {
  width: calc(100vw - (100vw - 100%));
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.gap {
  gap: 10px;
}

.background {
  background-color: #fafafa;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.nav-border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.nav-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.top-bar {
  background-image: linear-gradient(to right, #3f51b5, #00a9f4);
}

.v-select.v-input--is-dirty input {
  width: 0;
}

.v-select__selections {
  flex-wrap: nowrap !important;
  overflow: hidden !important;
}

.v-select__selections .v-select__selection {
  min-width: unset;
  min-width: fit-content;
}

.v-select:not(.v-input--is-dirty) input {
  width: 100px !important;
}

.v-select.autocomplete.v-input--is-dirty input {
  width: unset;
}
</style>
