import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";

const ACTION_ITEM_STATUSES_MAP = {
  [ACTION_ITEM_STATUSES.OPEN]: "Open",
  [ACTION_ITEM_STATUSES.READY_FOR_REVIEW]: "Ready for Review",
  [ACTION_ITEM_STATUSES.REJECTED]: "Rejected",
  [ACTION_ITEM_STATUSES.CANCELED]: "Canceled",
  [ACTION_ITEM_STATUSES.CLOSED]: "Closed",
};

export default ACTION_ITEM_STATUSES_MAP;
