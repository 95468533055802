import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showMenu: false,
    numUnsubmittedTickets: 0,
    selectedMapId: undefined,
    isUpdateAvailable: false,
    setIsNewNotification: false,
    mapChanged: false,
    reloadMap: false,
    downloadSummary: {},
    errorOccurred: false,
    badRequestError: undefined,
    forbiddenError: undefined,
    redrawLayers: false,
    sharedSiteTokenLoaded: false,
    sharedSiteSignedOut: false,
    sharedSite: {},
    formSubmitted: {
      isFormSubmitted: false,
      formResultId: undefined,
    },
    dynamicFormProps: {
      formDefinition: {
        form: {
          formDescription: { title: "" },
          sections: [{ items: [] }],
        },
      },
      locateRequestId: "",
      existingFormResultIdMap: {},
      canEdit: true,
      alreadySubmittedFinalOnline: false,
      selectedPdfFileUrl: "",
      globalId: "",
      objectId: 0,
      selectedMapServiceId: "",
    },
    formDeleted: {
      isFormDeleted: false,
      formResultId: undefined,
    },
    myLastMapSet: false,
    notFoundError: false,
    showingNotificationPane: false,
    cityworksError: undefined,
  },
  mutations: {
    toggleMenu(state) {
      state.showMenu = !state.showMenu;
    },
    setMenuState(state, payload) {
      state.showMenu = payload;
    },
    setNumUnsubmittedTickets(state, payload) {
      state.numUnsubmittedTickets = payload;
    },
    setSelectedMapId(state, payload) {
      state.selectedMapId = payload;
    },
    setIsUpdateAvailable(state, payload) {
      state.isUpdateAvailable = payload;
    },
    setIsNewNotifications(state, payload) {
      state.setIsNewNotification = payload;
    },
    setIsMapChanged(state, payload) {
      state.mapChanged = payload;
    },
    setIsReloadMap(state, payload) {
      state.reloadMap = payload;
    },
    setDownloadSummary(state, payload) {
      state.downloadSummary = payload;
    },
    setErrorOccurred(state, payload) {
      state.errorOccurred = payload;
    },
    setBadRequestError(state, payload) {
      state.badRequestError = payload;
    },
    setForbiddenError(state, payload) {
      state.forbiddenError = payload;
    },
    setIsRedrawLayers(state, payload) {
      state.redrawLayers = payload;
    },
    setSharedSiteTokenLoaded(state, payload) {
      state.sharedSiteTokenLoaded = payload;
    },
    setSharedSiteSignedOut(state, payload) {
      state.sharedSiteSignedOut = payload;
    },
    setFormSubmitted(state, payload) {
      state.formSubmitted = payload;
    },
    setSharedSite(state, payload) {
      state.sharedSite = payload;
    },
    setDynamicFormProps(state, payload) {
      state.dynamicFormProps = payload;
    },
    setFormDeleted(state, payload) {
      state.formDeleted = payload;
    },
    setMyLastMapSet(state, payload) {
      state.myLastMapSet = payload;
    },
    setNotFoundError(state, payload) {
      state.notFoundError = payload;
    },
    setShowingNotificationPane(state, payload) {
      state.showingNotificationPane = payload;
    },
    setCityworksError(state, payload) {
      state.cityworksError = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    isMenuOpen(state) {
      return state.showMenu;
    },
    numUnsubmittedTickets(state) {
      return state.numUnsubmittedTickets;
    },
    mapIdSelected(state) {
      return state.selectedMapId;
    },
    isUpdateAvailable(state) {
      return state.isUpdateAvailable;
    },
    unreadNotifications(state) {
      return state.setIsNewNotification;
    },
    isMapChanged(state) {
      return state.mapChanged;
    },
    isReloadMap(state) {
      return state.reloadMap;
    },
    downloadSummaryData(state) {
      return state.downloadSummary;
    },
    errorOccurred(state) {
      return state.errorOccurred;
    },
    badRequestError(state) {
      return state.badRequestError;
    },
    forbiddenError(state) {
      return state.forbiddenError;
    },
    isRedrawLayers(state) {
      return state.redrawLayers;
    },
    sharedSiteTokenLoaded(state) {
      return state.sharedSiteTokenLoaded;
    },
    sharedSiteSignedOut(state) {
      return state.sharedSiteSignedOut;
    },
    formSubmitted(state) {
      return state.formSubmitted;
    },
    sharedSite(state) {
      return state.sharedSite;
    },
    dynamicFormProps(state) {
      return state.dynamicFormProps;
    },
    formDeleted(state) {
      return state.formDeleted;
    },
    myLastMapSet(state) {
      return state.myLastMapSet;
    },
    notFoundError(state) {
      return state.notFoundError;
    },
    showingNotificationPane(state) {
      return state.showingNotificationPane;
    },
    cityworksError(state) {
      return state.cityworksError;
    },
  },
});
