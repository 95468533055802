<template>
  <v-dialog
    v-model="showUserNotificationsDialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="overflow-y-auto">
      <v-toolbar dark color="#3F51B5" class="elevation-0">
        <v-toolbar-title>Notifications</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('user-notifications-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <div>
          <v-row>
            <v-col
              cols="6"
              class="text-caption font-weight-medium"
              style="color: black"
            >
              Notification
            </v-col>
            <v-col
              cols="2"
              class="text-caption font-weight-medium"
              style="color: black; padding-left: 8px"
            >
              Email
            </v-col>
            <v-col
              cols="2"
              class="text-caption font-weight-medium"
              style="color: black; padding-left: 10px"
            >
              Text
            </v-col>
            <v-col
              cols="2"
              class="text-caption font-weight-medium"
              style="color: black; padding-left: 6px"
            >
              In-App
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="10"
              class="text-subtitle-1 my-0 py-0"
              style="color: #444444"
            >
              Utilibot Failures
            </v-col>
            <v-col cols="2" class="my-0 py-0">
              <v-checkbox
                class="my-0 py-0"
                input-value="true"
                :disabled="true"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row
            class="py-1 my-1"
            v-for="notificationSetting of newNotificationSettings.filter(
              (setting) => setting.notification_type !== 'daily_task_summary'
            )"
            :key="notificationSetting.id"
            style="max-height: 24px"
          >
            <v-col
              cols="6"
              class="text-subtitle-1 my-0 py-0"
              style="color: #444444"
            >
              {{ getFormattedName(notificationSetting.notification_type) }}
            </v-col>
            <v-col cols="2" class="my-0 py-0">
              <v-checkbox
                class="my-0 py-0"
                v-model="notificationSetting.email"
              ></v-checkbox>
            </v-col>
            <v-col
              v-if="userInformation.sms_notification === null"
              cols="2"
              class="my-0 py-0"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-checkbox
                      v-model="notificationSetting.sms"
                      class="my-0 py-0"
                      :disabled="!userInformation.sms_notification"
                    ></v-checkbox>
                  </span>
                </template>
                <span>Add a phone number to enable</span>
              </v-tooltip>
            </v-col>
            <v-col v-else cols="2" class="my-0 py-0">
              <v-checkbox
                class="my-0 py-0"
                v-model="notificationSetting.sms"
              ></v-checkbox>
            </v-col>
            <v-col cols="2" class="my-0 py-0">
              <v-checkbox
                class="my-0 py-0"
                v-model="notificationSetting.in_app"
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <div
        class="d-flex mx-1 my-1 px-2 py-2"
        :class="{ mobileScreen: $vuetify.breakpoint.xsOnly }"
      >
        <v-spacer></v-spacer>

        <v-btn color="#3F51B5" dark @click="saveNotifications()">
          Save Notifications
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { axiosWithRegularAuth } from "@/plugins/axios";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "UserNotificationsDialog",
  props: {
    showUserNotificationsDialog: Boolean,
    userNotifications: Array,
    userInformation: Object,
  },
  data() {
    return {
      newNotificationSettings: [],
    };
  },
  methods: {
    getFormattedName(notificationName) {
      if (notificationName === "new_task_assigned") {
        return "New Task Assigned";
      }
    },
    async saveNotifications() {
      const payload = {
        notification_settings: [
          ...this.newNotificationSettings.map(({ id, ...u }) => {
            return u;
          }),
        ],
      };

      await axiosWithRegularAuth.put(
        `${APIURL}/user_notification_settings/${this.userInformation.user_id}`,
        payload
      );

      this.$emit("user-notifications-dialog-close");
      this.$emit("notifications-saved");
    },
  },
  watch: {
    userNotifications: {
      immediate: true,
      deep: true,
      handler() {
        this.newNotificationSettings = [
          ...this.userNotifications.map((u) => {
            if (!this.userInformation.sms_notifications) {
              u.sms = false;
            }
            return {
              id: uuidv4(),
              ...u,
            };
          }),
        ];
      },
    },
  },
};
</script>

<style scoped>
.v-input__slot {
  margin-bottom: 0px !important;
}

.v-messages {
  display: none !important;
}

.mobileScreen {
  position: absolute;
  bottom: 0;
  right: 8px;
}
</style>
