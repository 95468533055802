<template>
  <div>
    <v-list dense v-if="sharedSiteTokenLoaded">
      <v-list-item v-if="currentUser" class="px-0" id="userDropdown">
        <v-menu offset-y min-width="244px">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" color="#3F51B5">
              <v-list-item-content>
                <section class="d-flex justify-end align-center">
                  <div
                    style="color: #3f51b5"
                    class="mx-0 px-0"
                    :style="{
                      width: $vuetify.breakpoint.smAndUp ? '180px' : undefined,
                    }"
                    :class="{
                      'flex-grow-0': $vuetify.breakpoint.smAndUp,
                      'flex-grow-1': $vuetify.breakpoint.xsOnly,
                      truncate: $vuetify.breakpoint.smAndUp,
                    }"
                  >
                    <section
                      style="font-size: 14px"
                      class="font-weight-medium"
                      :class="{ truncate: $vuetify.breakpoint.smAndUp }"
                    >
                      {{ currentUser.email }}
                    </section>
                  </div>

                  <v-btn icon color="#673AB7" class="px-0 mx-0">
                    <v-icon color="#3F51B5">{{ mdiChevronDown }}</v-icon>
                  </v-btn>
                </section>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list class="py-0 px-0">
            <v-list-item
              @click="signOut"
              class="py-1 px-0"
              id="siteContactSignOut"
            >
              <v-btn
                class="min-button"
                id="sign-out-button"
                text
                color="#3f51b5"
              >
                <v-icon class="mr-2">{{ mdiLogoutVariant }}</v-icon>
                Sign Out
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        active-class="text--lighten-5"
        class="py-1 my-0"
        to="/sites"
        id="sitesList"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiFormatListCheckbox }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content style="color: #3f51b5" class="font-weight-medium">
          Sites
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/${publicSite.url_id}`"
        active-class="text--lighten-5"
        class="py-1 my-0"
        v-if="publicSite && publicSite.url_id"
        target="_blank"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiLink }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          style="color: #3f51b5"
          class="font-weight-medium"
          id="publicSite"
        >
          Public Site
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        href="https://utilisync.helpscoutdocs.com/collection/240-site-contacts#"
        target="_blank"
        class="py-1 my-0"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiBookOpen }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          style="color: #3f51b5"
          class="font-weight-medium"
          id="knowledgeBase"
        >
          Knowledge Base
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="showChat" class="py-1 my-0">
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiHelpCircle }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          style="color: #3f51b5"
          class="font-weight-medium"
          id="support"
        >
          Support
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="reload" class="py-1 my-0">
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiReload }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          style="color: #3f51b5"
          class="font-weight-medium"
          id="reload"
        >
          Reload
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item to="/versionLog" class="py-1 my-0">
        <v-list-item-content
          style="font-size: 14px; color: #3f51b5"
          class="font-weight-medium"
        >
          Version {{ versionNumber }}
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
    </v-list>
  </div>
</template>

<script>
import {
  mdiFormatListCheckbox,
  mdiLink,
  mdiLogoutVariant,
  mdiBell,
  mdiChevronDown,
  mdiAccount,
  mdiBookOpen,
  mdiReload,
  mdiHelpCircle,
} from "@mdi/js";
import { mapMutations } from "vuex";
import { axiosWithJwtAuth } from "@/plugins/axios";
import releaseNotes from "@/constants/releaseNotes";
import authObjectMixin from "@/mixins/authObjectMixin";
import jwt_decode from "jwt-decode";
import { mapGetters } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteNavDrawerList",
  computed: {
    versionNumber() {
      return this.releaseNotes[0].version;
    },
    ...mapGetters(["sharedSiteTokenLoaded", "sharedSite"]),
  },
  props: {
    showUploadTickets: Boolean,
  },
  mixins: [authObjectMixin],
  data() {
    return {
      mdiFormatListCheckbox,
      mdiLink,
      mdiLogoutVariant,
      mdiBell,
      mdiChevronDown,
      mdiAccount,
      mdiBookOpen,
      mdiReload,
      mdiHelpCircle,
      currentUser: {},
      numTickets: 0,
      showSignOutDialog: false,
      releaseNotes,
      currentUserOrg: {},
      publicSite: {},
    };
  },
  methods: {
    async reload() {
      this.$router.go(0);
    },
    async getUserData() {
      if (!this.sharedSiteTokenLoaded) {
        return;
      }
      const token = localStorage.getItem("shared-site-token");
      const decoded = jwt_decode(token);
      const { email_address: emailAddress } = decoded;
      if (!emailAddress) {
        return;
      }

      if (this.$route.query.siteId) {
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/users/one/${this.$route.query.siteId}`
        );
        this.currentUser = results;

        const {
          data: { results: currentUserOrg },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/user_group/${this.$route.query.siteId}`
        );
        this.currentUserOrg = currentUserOrg;
      } else {
        this.currentUser = {
          email: emailAddress,
        };
      }
    },
    async signOut() {
      localStorage.clear();
      this.setMenuState(false);
      this.setSharedSiteSignedOut(true);
      this.$router.push({ name: "Sites" });
    },
    showChat() {
      if (!navigator.onLine) {
        return;
      }
      window.Beacon("init", "9aeaf1d7-aad6-49a6-a7db-459b23b366ca");
      window.Beacon("on", "ready", () => {
        window.Beacon("open");
      });
      window.Beacon("on", "close", () => {
        window.Beacon("destroy");
      });
    },
    ...mapMutations([
      "setMenuState",
      "setSharedSiteTokenLoaded",
      "setSharedSiteSignedOut",
    ]),
  },
  watch: {
    sharedSite: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        const { map_service_id: mapServiceId, feature_id: objectId } = val;
        if (!mapServiceId || !objectId) {
          this.publicSite = {};
          return;
        }

        const {
          data: { results = {} },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/public_site/map_service/${mapServiceId}/feature/${objectId}`
        );
        this.publicSite = results;
      },
    },
    sharedSiteTokenLoaded: {
      immediate: true,
      handler() {
        this.getUserData();
      },
    },
  },
  beforeDestroy() {
    window.Beacon("destroy");
  },
};
</script>

<style>
.overflow-y-auto {
  overflow-y: auto;
}

.min-button::before {
  display: none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
