<template>
  <v-dialog
    v-model="showUserInformationDialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="#3F51B5" class="elevation-0">
        <v-toolbar-title>User Information</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('user-information-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-2">
        <div>
          <div class="text-subtitle-1 font-weight-medium" style="color: black">
            General Information
          </div>
          <div class="d-flex justify-space-between my-2">
            <v-row>
              <v-col cols="6">
                <div>
                  <div class="text-caption">Name</div>
                  <div class="text-subtitle-1 text--primary">
                    {{ userInformation.f_name }} {{ userInformation.l_name }}
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <div>
                  <div class="text-caption">
                    Phone Number For Text Notifications
                  </div>
                  <div class="text-subtitle-1 text--primary">
                    {{
                      this.formatPhoneNumber(userInformation.sms_notification)
                    }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div>
            <div class="text-caption">Email</div>
            <div class="text-subtitle-1 text--primary">
              {{ userInformation.email }}
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="text-subtitle-1 font-weight-medium" style="color: black">
            Permissions
          </div>

          <div class="d-flex justify-space-between my-2">
            <v-row>
              <v-col cols="6">
                <div>
                  <div class="text-caption">Admin</div>
                  <div
                    v-if="userInformation.is_gis_admin"
                    class="text-subtitle-1 text--primary"
                  >
                    Yes
                  </div>
                  <div v-else class="text-subtitle-1 text--primary">No</div>
                </div>
              </v-col>
              <v-col cols="6">
                <div>
                  <div class="text-caption">User Type</div>
                  <div
                    v-if="userInformation.is_power_user"
                    class="text-subtitle-1 text--primary"
                  >
                    Power User
                  </div>
                  <div
                    v-else-if="userInformation.is_standard_user"
                    class="text-subtitle-1 text--primary"
                  >
                    Standard User
                  </div>
                  <div
                    v-else-if="userInformation.is_view_only_user"
                    class="text-subtitle-1 text--primary"
                  >
                    View Only User
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UserInformationDialog",
  props: {
    showUserInformationDialog: Boolean,
    userInformation: Object,
  },
  methods: {
    formatPhoneNumber(phoneNumber) {
      if (phoneNumber !== null) {
        const number = new String(phoneNumber);
        return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(
          6
        )}`;
      } else {
        return "";
      }
    },
  },
};
</script>
