<template>
  <div>
    <v-list dense>
      <v-list-item v-if="currentUser" class="px-0">
        <v-menu offset-y min-width="244px">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" color="#3F51B5">
              <v-list-item-content>
                <section class="d-flex justify-space-between align-center">
                  <div
                    class="mx-0 px-0"
                    :style="{
                      width: $vuetify.breakpoint.smAndUp ? '150px' : undefined,
                    }"
                    :class="{
                      'flex-grow-0': $vuetify.breakpoint.smAndUp,
                      'flex-grow-1': $vuetify.breakpoint.xsOnly,
                      truncate: $vuetify.breakpoint.smAndUp,
                    }"
                  >
                    <div
                      class="caption menu-text font-weight-medium indigo--text"
                      :class="{ truncate: $vuetify.breakpoint.smAndUp }"
                    >
                      {{
                        currentUser.organization &&
                        currentUser.organization.name
                      }}
                    </div>
                    <div
                      class="font-weight-medium indigo--text"
                      :class="{ truncate: $vuetify.breakpoint.smAndUp }"
                      style="font-size: 16px"
                    >
                      {{ userInformation.f_name }}
                      {{ userInformation.l_name }}
                    </div>
                  </div>

                  <v-btn icon color="#673AB7" class="px-0 mx-0">
                    <v-icon color="#3F51B5">{{ mdiChevronDown }}</v-icon>
                  </v-btn>
                </section>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list class="py-0 px-0">
            <v-list-item
              @click="showUserInformationDialog = true"
              class="py-0 px-0"
            >
              <v-btn class="min-button" text color="#3f51b5">
                <v-icon class="mr-2">{{ mdiAccount }}</v-icon>
                User Information
              </v-btn>
            </v-list-item>
            <v-list-item
              @click="showUserNotificationsDialog = true"
              class="py-0 px-0"
            >
              <v-btn class="min-button" text color="#3f51b5">
                <v-icon class="mr-2">{{ mdiBell }}</v-icon>
                Notifications
              </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-if="!hasValidCityworksToken"
              @click="showCityworksSignInDialog = true"
              class="py-0 px-0"
            >
              <v-btn class="min-button" text color="#3f51b5">
                <v-icon class="mr-2">{{ mdiLoginVariant }}</v-icon>
                Sign In - Cityworks
              </v-btn>
            </v-list-item>
            <v-list-item v-else @click="signOutOfCityworks" class="py-0 px-0">
              <v-btn class="min-button" text color="#3f51b5">
                <v-icon class="mr-2">{{ mdiLogoutVariant }}</v-icon>
                Sign Out - Cityworks
              </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="showWarningDialogOrSignOut" class="py-0 px-0">
              <v-btn
                class="min-button"
                id="sign-out-button"
                text
                color="#3f51b5"
              >
                <v-icon class="mr-2">{{ mdiLogoutVariant }}</v-icon>
                Sign Out
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item to="/map" active-class="text--lighten-5" class="py-0 my-0">
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiMap }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Maps
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item
        :to="{
          path: '/upload',
        }"
        id="upload-tickets"
        class="py-1 my-0 align-center"
      >
        <v-list-item-icon class="align-center">
          <v-icon color="#3F51B5">{{ mdiUpload }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Upload
        </v-list-item-content>
        <v-list-item-avatar v-if="numUnsubmittedTickets > 0" class="my-0 my-0">
          <v-avatar color="orange" size="25">
            <span class="menu-text headline pa-2 caption">
              {{ numUnsubmittedTickets }}
            </span>
          </v-avatar>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item
        :to="{
          path: '/list',
        }"
        active-class="text--lighten-5"
        class="py-0 my-0"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiFormatListCheckbox }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Layers
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{
          path: '/scheduledForms',
        }"
        active-class="text--lighten-5"
        class="py-0 my-0"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiCalendarCheck }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Scheduled Forms
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{
          path: '/actionItems',
        }"
        active-class="text--lighten-5"
        class="py-0 my-0"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiCheckboxMarkedOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Action Items
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/formSubmission" class="py-0 my-0">
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiFileMultipleOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Form Submissions
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        @click="$emit('open-capture-upload-dialog')"
        class="py-0 my-0"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiCameraIris }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Capture Screen
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        href="https://utilisync.helpscoutdocs.com/collection/1-field-reports"
        target="_blank"
        class="py-0 my-0"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiBookOpen }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Knowledge Base
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="showChat" class="py-0 my-0">
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiHelpCircle }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Support
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        @click="reload"
        class="py-0 my-0 d-flex align-center"
        :two-line="Boolean(lastFullDownloadCompleted)"
      >
        <div
          class="my-0 py-0 pr-8 d-flex align-center"
          v-if="lastFullDownloadCompleted"
        >
          <v-progress-circular
            indeterminate
            :size="20"
            v-if="reloading"
            color="#3F51B5"
          ></v-progress-circular>
          <v-icon color="#3F51B5" v-else>{{ mdiReload }}</v-icon>
        </div>
        <v-list-item-icon v-else>
          <v-progress-circular
            indeterminate
            :size="20"
            v-if="reloading"
            color="#3F51B5"
          ></v-progress-circular>
          <v-icon color="#3F51B5" v-else>{{ mdiReload }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          <div>Sync and Reload</div>
          <div class="caption" v-if="lastFullDownloadCompleted">
            Last Synced
            {{ lastFullDownloadCompleted | formatVariableLocalDate("hh:mm A") }}
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="currentUser && currentUser.is_gis_admin"
        @click="openUserAdmin()"
        class="py-0 my-0"
      >
        <v-list-item-icon>
          <v-icon color="#3F51B5">{{ mdiOpenInNew }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium indigo--text">
          Admin App
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item to="/versionLog" class="py-0 my-0">
        <v-list-item-content
          style="font-size: 14px; color: #3f51b5"
          class="font-weight-medium indigo--text"
        >
          Version {{ versionNumber }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <v-dialog v-model="showSignOutDialog" max-width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                There are tickets saved on this device that have not been
                submitted. If you proceed, the forms will be permanently
                deleted. Do you wish to continue?
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showSignOutDialog = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="signOut">
            Sign Out and Delete Forms from device
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CityworksSignInDialog
      v-if="showCityworksSignInDialog"
      :showCityworksSignInDialog="showCityworksSignInDialog"
      @cityworks-sign-in-dialog-close="showCityworksSignInDialog = false"
      @cityworks-sign-in-dialog-succeed="
        showCityworksSignInDialog = false;
        updateCityworksToken();
      "
    />
    <UserInformationDialog
      v-if="showUserInformationDialog"
      :showUserInformationDialog="showUserInformationDialog"
      :userInformation="userInformation"
      @user-information-dialog-close="showUserInformationDialog = false"
    />

    <UserNotificationsDialog
      v-if="showUserNotificationsDialog"
      :showUserNotificationsDialog="showUserNotificationsDialog"
      :userNotifications="userNotifications"
      :userInformation="userInformation"
      @user-notifications-dialog-close="showUserNotificationsDialog = false"
      @notifications-saved="getUserNotifications"
    />
  </div>
</template>

<script>
import {
  mdiChevronDown,
  mdiMap,
  mdiFormatListCheckbox,
  mdiFileMultipleOutline,
  mdiBookOpen,
  mdiHelpCircle,
  mdiOpenInNew,
  mdiMonitorDashboard,
  mdiReload,
  mdiPencil,
  mdiLogoutVariant,
  mdiAccount,
  mdiLoginVariant,
  mdiBell,
  mdiCalendarCheck,
  mdiCheckboxMarkedOutline,
  mdiUpload,
  mdiCameraIris,
} from "@mdi/js";
import { db } from "@/mixins/utilisync-db";
import { mapGetters, mapMutations } from "vuex";
import { axiosWithRegularAuth } from "@/plugins/axios";
import releaseNotes from "@/constants/releaseNotes";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import CityworksSignInDialog from "@/components/app/CityworksSignInDialog";
import UserInformationDialog from "@/components/app/UserInformationDialog";
import UserNotificationsDialog from "@/components/app/UserNotificationsDialog";
import authObjectMixin from "@/mixins/authObjectMixin";
import axios from "axios";
import moment from "moment";

const cancelTokenSource = axios.CancelToken.source();
const APIURL = process.env.VUE_APP_API_URL;
const ADMIN_URL = process.env.VUE_APP_USER_ADMIN_URL;

export default {
  name: "NavDrawerList",
  computed: {
    versionNumber() {
      return this.releaseNotes[0].version;
    },
    ...mapGetters(["numUnsubmittedTickets"]),
  },
  props: {
    showUploadTickets: Boolean,
  },
  mixins: [downloadDataMixin, bulkDownloadDataMixin, authObjectMixin],
  components: {
    CityworksSignInDialog,
    UserInformationDialog,
    UserNotificationsDialog,
  },
  data() {
    return {
      mdiChevronDown,
      mdiMap,
      mdiFormatListCheckbox,
      mdiFileMultipleOutline,
      mdiBookOpen,
      mdiHelpCircle,
      mdiOpenInNew,
      mdiMonitorDashboard,
      mdiReload,
      mdiPencil,
      mdiLogoutVariant,
      mdiAccount,
      mdiLoginVariant,
      mdiBell,
      mdiCalendarCheck,
      mdiCheckboxMarkedOutline,
      mdiUpload,
      mdiCameraIris,
      currentUser: {},
      numTickets: 0,
      showSignOutDialog: false,
      isOnline: true,
      releaseNotes,
      showCityworksSignInDialog: false,
      showUserInformationDialog: false,
      showUserNotificationsDialog: false,
      auth: {},
      hasValidCityworksToken: false,
      userInformation: {},
      userNotifications: [],
      reloading: false,
      lastFullDownloadCompleted: undefined,
      showOpenSendRecordingDialog: false,
    };
  },
  methods: {
    openUserAdmin() {
      window.open(ADMIN_URL, "_blank");
    },
    async getUserInformation() {
      if (!this.currentUser || !navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/users/${this.currentUser?.user_id}`
      );
      this.userInformation = results;
    },
    async getUserNotifications() {
      if (!this.currentUser || !navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/user_notification_settings/${this.currentUser.user_id}`
      );
      this.userNotifications = results;
    },
    async validateCityworksToken() {
      if (!navigator.onLine) {
        return;
      }

      const { cityworks_token: cityworksToken } = this.auth ?? {};
      if (!cityworksToken) {
        this.hasValidCityworksToken = false;
        return;
      }
      try {
        const {
          data: { error },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/cityworks/validate_token`,
          {
            params: {
              cityworks_token: cityworksToken,
            },
          }
        );
        this.hasValidCityworksToken = !error;
      } catch (error) {
        this.hasValidCityworksToken = false;
      } finally {
        if (!this.hasValidCityworksToken) {
          // eslint-disable-next-line no-unused-vars
          const { cityworks_token, ...newAuth } = this.auth;
          localStorage.setItem("auth", JSON.stringify(newAuth));
        }
      }
    },
    async signOutOfCityworks() {
      const { user_id: userId } = this.auth;
      await axiosWithRegularAuth.post(
        `${APIURL}/cityworks/sign_user_out`,
        undefined,
        {
          params: {
            user_id: userId,
          },
        }
      );
      await this.updateCityworksToken();
    },
    async updateCityworksToken() {
      await this.updateAuthObject();
      await this.validateCityworksToken();
    },
    async reload() {
      if (!this.isOnline) {
        return;
      }
      this.reloading = true;
      await this.clearData();
      await db.open();
      await this.bulkDownloadData();
      await this.downloadData();
      localStorage.setItem("last-updated", new Date().toString());
      this.reloading = false;
      this.$router.go(0);
      this.getLastFullDownloadDate();
    },
    async showWarningDialogOrSignOut() {
      cancelTokenSource.cancel();
      await db.delete();
      const layerVisibilitySettings = localStorage.getItem(
        "layer-visibility-settings"
      );
      localStorage.clear();
      localStorage.setItem(
        "layer-visibility-settings",
        layerVisibilitySettings
      );
      this.setMenuState(false);
      this.$router.push({ name: "Login", query: undefined }, () => {
        this.$router.go(0);
      });
    },
    async clearData() {
      localStorage.removeItem("last-updated");
      await db.delete();
    },
    async signOut() {
      cancelTokenSource.cancel();
      const layerVisibilitySettings = localStorage.getItem(
        "layer-visibility-settings"
      );
      await this.clearData();
      localStorage.clear();
      localStorage.setItem(
        "layer-visibility-settings",
        layerVisibilitySettings
      );
      this.showSignOutDialog = false;
      this.$router.push({ name: "Login", query: undefined }, () => {
        this.$router.go(0);
      });
    },
    showChat() {
      if (!navigator.onLine) {
        return;
      }
      window.Beacon("init", "e75a5c8c-dd64-47b3-bb57-b1d8e7e8c2dc");
      window.Beacon("on", "ready", () => {
        window.Beacon("open");
      });
      window.Beacon("on", "close", () => {
        window.Beacon("destroy");
      });
    },
    openSendRecordingDialog() {
      this.showOpenSendRecordingDialog = true;
    },
    async onOnline() {
      this.isOnline = true;
    },
    async onOffline() {
      this.isOnline = false;
    },

    getUserData() {
      this.currentUser = JSON.parse(localStorage.getItem("auth"));
      const auth = JSON.parse(localStorage.getItem("auth"));
      const {
        email,
        user_id: userId,
        username,
        f_name: fName,
        l_name: lName,
        user_created: userCreated,
        is_gis_admin: isGisAdmin,
        is_locate_request_user: isLocateRequestUser,
        is_power_user: isPowerUser,
        is_view_only_user: isViewOnlyUser,
        is_sys_admin: isSysAdmin,
        is_standard_user: isStandardUser,
        organization,
        last_seen: lastSeen,
      } = auth ?? {};

      const props = {
        email: email ?? "",
        user_id: userId,
        username,
        name: `${fName} ${lName}`,
        user_created: moment(userCreated * 1000).format("YYYY-MM-DD"),
        is_admin: isGisAdmin?.toString(),
        is_locate_request_user: isLocateRequestUser?.toString(),
        is_power_user: isPowerUser?.toString(),
        is_view_only_user: isViewOnlyUser?.toString(),
        is_sys_admin: isSysAdmin?.toString(),
        is_standard_user: isStandardUser?.toString(),
        company: organization?.name,
        organization_gis_connection_type: organization?.gis_connection_type,
        domain: window.location.hostname,
        app_version: this.versionNumber,
        last_seen: moment(lastSeen).format("YYYY-MM-DD"),
      };
      window.Beacon("identify", props);
      window.Beacon("config", {
        mode: "askFirst",
      });
      window.Beacon("event", {
        type: "page-viewed",
        url: document.location.href,
        title: document.title,
      });
      window.Beacon("suggest");

      if (navigator.onLine) {
        //make sure app is online before trying to open Beacon
        window.Beacon("open");
        window.Beacon("close");
      }
    },
    getLastFullDownloadDate() {
      this.lastFullDownloadCompleted = localStorage.getItem(
        "last-full-download-completed"
      );
    },
    ...mapMutations(["setNumUnsubmittedTickets", "setMenuState"]),
  },
  async mounted() {
    await this.getUserData();
    await this.getUserInformation();
    await this.getUserNotifications();
    window.addEventListener("offline", this.onOffline);
    window.addEventListener("online", this.onOnline);
  },
  beforeMount() {
    this.auth = JSON.parse(localStorage.getItem("auth")) ?? {};
    this.validateCityworksToken();
    this.getLastFullDownloadDate();
  },
  beforeDestroy() {
    window.Beacon("destroy");
  },
};
</script>

<style>
.overflow-y-auto {
  overflow-y: auto;
}

.min-button::before {
  display: none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style scoped>
.theme--light.v-navigation-drawer .v-divider {
  border-color: rgba(0, 0, 0, 0.25) !important;
}
</style>
