<template>
  <v-dialog :value="showForbiddenErrorDialog" max-width="500px" persistent>
    <v-card>
      <v-card-text class="py-3">
        <div class="mx-0" v-if="displayedForbiddenError">
          {{ displayedForbiddenError }}
        </div>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text color="#3F51B5" @click="closeDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

const defaultDynamicFormProps = {
  formDefinition: {
    form: {
      formDescription: { title: "" },
      sections: [{ items: [] }],
    },
  },
  locateRequestId: "",
  existingFormResultIdMap: {},
  canEdit: true,
  alreadySubmittedFinalOnline: false,
  selectedPdfFileUrl: "",
  globalId: "",
  objectId: 0,
  selectedMapServiceId: "",
};

export default {
  name: "ForbiddenErrorDialog",
  props: {
    showForbiddenErrorDialog: Boolean,
  },
  computed: {
    ...mapGetters(["forbiddenError", "dynamicFormProps"]),
    displayedForbiddenError() {
      if (
        [
          "'cityworks_token' is not valid",
          "'cityworks_token' is required for this request",
        ].includes(this.forbiddenError)
      ) {
        return "Your Cityworks token has expired or is missing";
      }
      return this.forbiddenError;
    },
  },
  methods: {
    closeDialog() {
      this.setDynamicFormProps(defaultDynamicFormProps);
      if (
        [
          "'cityworks_token' is not valid",
          "'cityworks_token' is required for this request",
        ].includes(this.forbiddenError)
      ) {
        this.$emit("cityworks-token-error");
      }
      this.setForbiddenError(undefined);
    },
    ...mapMutations(["setForbiddenError", "setDynamicFormProps"]),
  },
};
</script>
