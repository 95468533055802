var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.showUserInformationDialog,
        callback: function($$v) {
          _vm.showUserInformationDialog = $$v
        },
        expression: "showUserInformationDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "#3F51B5" }
            },
            [
              _c("v-toolbar-title", [_vm._v("User Information")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("user-information-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "py-2" }, [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "text-subtitle-1 font-weight-medium",
                  staticStyle: { color: "black" }
                },
                [_vm._v(" General Information ")]
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between my-2" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", [
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v("Name")
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-subtitle-1 text--primary" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.userInformation.f_name) +
                                  " " +
                                  _vm._s(_vm.userInformation.l_name) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", [
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v(" Phone Number For Text Notifications ")
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-subtitle-1 text--primary" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.formatPhoneNumber(
                                      _vm.userInformation.sms_notification
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [
                _c("div", { staticClass: "text-caption" }, [_vm._v("Email")]),
                _c("div", { staticClass: "text-subtitle-1 text--primary" }, [
                  _vm._v(" " + _vm._s(_vm.userInformation.email) + " ")
                ])
              ])
            ]),
            _c("div", { staticClass: "mt-4" }, [
              _c(
                "div",
                {
                  staticClass: "text-subtitle-1 font-weight-medium",
                  staticStyle: { color: "black" }
                },
                [_vm._v(" Permissions ")]
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between my-2" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", [
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v("Admin")
                          ]),
                          _vm.userInformation.is_gis_admin
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary"
                                },
                                [_vm._v(" Yes ")]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary"
                                },
                                [_vm._v("No")]
                              )
                        ])
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", [
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v("User Type")
                          ]),
                          _vm.userInformation.is_power_user
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary"
                                },
                                [_vm._v(" Power User ")]
                              )
                            : _vm.userInformation.is_standard_user
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary"
                                },
                                [_vm._v(" Standard User ")]
                              )
                            : _vm.userInformation.is_view_only_user
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-subtitle-1 text--primary"
                                },
                                [_vm._v(" View Only User ")]
                              )
                            : _vm._e()
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }