<template>
  <v-dialog :value="showAlreadyClearedDialog" max-width="500px">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              Ticket
              {{ ticketNumbersAlreadySubmittedWithoutDuplicates.join(", ") }}
              has been cleared. Do you want to permanently delete the forms for
              that ticket that have been saved on this device? If you keep your
              form, it will be submitted and overwrite the form that was
              previously submitted.
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="
            $emit('delete-forms');
            deleteForms();
          "
        >
          Delete Forms
        </v-btn>
        <v-btn color="blue darken-1" text @click="$emit('keep-forms')">
          Keep Forms
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from "@/mixins/utilisync-db";
import { mapMutations } from "vuex";

export default {
  name: "AlreadyClearedDialog",
  props: {
    showAlreadyClearedDialog: Boolean,
    ticketNumbersAlreadySubmitted: Array,
    formResultIdsAlreadySubmitted: Array,
    submittedFinalTicketOffline: Boolean,
  },
  computed: {
    ticketNumbersAlreadySubmittedWithoutDuplicates() {
      return [...new Set(this.ticketNumbersAlreadySubmitted)];
    },
  },
  methods: {
    async deleteForms() {
      if (this.submittedFinalTicketOffline) {
        return;
      }
      for (const formResultId of this.formResultIdsAlreadySubmitted) {
        await db.formResults
          .where("form_result_id")
          .equals(formResultId)
          .delete();
      }
      this.setNumUnsubmittedTickets(0);
    },
    ...mapMutations(["setNumUnsubmittedTickets"]),
  },
};
</script>
