<template>
  <v-dialog v-model="showCaptureShowWidget" max-width="500px" persistent>
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <v-toolbar-title>
          <div>View Capture</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="tryClose()">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="uploaded">
        <v-card-text>
          <div>
            The capture link has been created. Copy the capture link below and
            send to UtiliSync Support.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="d-flex">
            <v-btn color="#00A9F4" text :to="`${videoLink}`" target="_blank">
              <v-icon class="mr-2">{{ mdiOpenInNew }}</v-icon> View</v-btn
            >
            <v-btn color="#3F51B5" dark @click="copyCaptureLink()" class="ml-2">
              <v-icon class="mr-2">{{ mdiContentCopy }}</v-icon
              >Copy</v-btn
            >
          </div>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-text class="pa-3">
          <div>
            Your video recording has been captured. Upload to create a capture
            link.
          </div>
          <div class="d-flex justify-center">
            <video
              ref="videoElement"
              controls
              width="480"
              height="360"
              class="videoVisual"
            ></video>
          </div>
          <div class="mt-2">
            <div class="d-flex justify-center" v-if="isUploading">
              <v-progress-linear
                v-model="uploadPercentage"
                color="#00A9F4"
                height="25"
                rounded
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </div>
            <div v-else style="height: 25px"></div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-5 pt-0 pb-3">
          <v-btn color="#00A9F4" @click="downloadVideo()" text>
            <v-icon class="mr-2">{{ mdiDownload }}</v-icon
            >Download
          </v-btn>

          <v-btn
            color="#3F51B5"
            @click="uploadCapture()"
            class="white--text"
            :disabled="isUploading"
          >
            <v-icon class="mr-2">{{ mdiUpload }}</v-icon
            >Upload
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <v-snackbar v-model="showCopiedLinkSnack" :timeout="5000">
      Copied capture link!
    </v-snackbar>
    <v-dialog
      v-model="showWarningDialog"
      v-if="isUploading"
      persistent
      max-width="450px"
    >
      <v-card>
        <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
          <v-toolbar-title>
            <div>Currently Uploading</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showWarningDialog = false">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-2">
          Your capture is currently uploading. If you close now, your capture
          may be lost.</v-card-text
        >
        <v-card-actions class="d-flex justify-end px-5 py-3">
          <v-btn color="#00A9F4" @click="showWarningDialog = false" text>
            Return To Upload
          </v-btn>
          <v-btn
            color="#3F51B5"
            @click="$emit('capture-show-dialog-close')"
            dark
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiOpenInNew,
  mdiContentCopy,
  mdiUpload,
  mdiDownload,
} from "@mdi/js";
import moment from "moment";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;
const FRONT_END_URL = process.env.VUE_APP_FRONT_END_URL;

export default {
  name: "CaptureShowDialog",
  props: {
    showCaptureShowWidget: Boolean,
    videoURL: String,
  },
  data() {
    return {
      mdiClose,
      mdiOpenInNew,
      mdiContentCopy,
      mdiUpload,
      mdiDownload,
      uploaded: false,
      videoLink: undefined,
      showCopiedLinkSnack: false,
      uploadPercentage: undefined,
      isUploading: false,
      showWarningDialog: false,
    };
  },
  computed: {
    isVideo() {
      return this.videoURL !== undefined;
    },
  },
  methods: {
    async uploadCapture() {
      const payload = new FormData();
      let file = undefined;
      if (this.isVideo) {
        const captureRes = await fetch(this.videoURL);
        const blob = await captureRes.blob();
        file = new File([blob], "capture.webm", {
          type: "video/webm",
        });
        payload.append("file_extension", "webm");
      }
      payload.append("file", file);
      payload.append("app", "Docs");

      if (file) {
        this.isUploading = true;

        const config = {
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };

        try {
          const response = await axiosWithRegularAuth.post(
            `${APIURL}/captures`,
            payload,
            config
          );
          const { results } = response.data;
          this.uploaded = true;
          this.videoLink = `/capture/${results.file_id}`;
        } catch (error) {
          // Handle any error that occurred during the upload
          console.error("Upload failed:", error);
        }

        this.isUploading = false;
        this.copyCaptureLink();
      }
    },
    downloadVideo() {
      const link = document.createElement("a");
      link.href = this.videoURL;
      link.download =
        "recorded_video_" + moment(Date.now()).format("YYYYMMDDHHMMSS");
      link.click();
    },
    async copyCaptureLink() {
      await navigator.clipboard.writeText(`${FRONT_END_URL}${this.videoLink}`);
      this.showCopiedLinkSnack = true;
    },
    tryClose() {
      if (this.isUploading) {
        this.showWarningDialog = true;
      } else {
        this.$emit("capture-show-dialog-close");
      }
    },
  },
  watch: {
    videoURL: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.videoElement.src = this.videoURL;
          this.$emit("close-capture-toolbar");
        });
      },
    },
  },
};
</script>
